import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Maybe } from '../../../../utils/types/maybe';
import { CommonComponent } from '../../../../utils/components/common-component';
import { InputOf } from '../../../../utils/input-reflector/input-of';

@Component({
	selector: 'app-deductibles-card',
	templateUrl: './deductibles-card.component.html',
	styleUrls: ['./deductibles-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeductiblesCardComponent extends CommonComponent implements OnInit {
	@Input()
	isRed: Maybe<boolean> = false;

	@Input()
	isNoNetwork: Maybe<boolean> = true;

	@HostBinding('class.red')
	hasRedClass = false;

	@HostBinding('class.green')
	hasGreenClass = false;

	@HostBinding('style.width') get hostWidth(): string {
		return this.isNoNetwork ? '100%' : '19.875rem';
	}

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'isRed'];
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.subsBag.add = this.inputs.one('isRed').subscribe({
			next: (isRed) => {
				this.hasRedClass = isRed;
				this.hasGreenClass = !isRed;
				this.changeDetectorRef.markForCheck();
			},
		});
	}
}
