import { Injectable } from '@angular/core';
import { UserOpenEnrollmentData } from 'src/app/models/open-enrollment.model';
import { Store } from 'src/app/utils/store/store';
import { OpenEnrollmentData } from '../../plan-selection.service';
import { MeUserStoreService } from '../me-user-store/me-user-store.service';
import { Observable, catchError, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { RxjsUtils } from '../../../utils/rxjs';
import { PlanSelectionAPIService } from '../../api/plan-selection-api/plan-selection-api.service';
import { isNil } from '../../../utils/is/is-nil';
import { getLocalDateFromUTCSeconds } from '../../../utils/utils';

export interface PlanSelectionData {
	userData?: UserOpenEnrollmentData;
	companyData?: OpenEnrollmentData;
	employeeEnrollment?: EmployeeEnrollmentData;
}

export interface EmployeeEnrollmentData {
	premiums: any[];
	effectiveDate?: string;
	metadata?: EmployeeEnrollmentMetadata;
}

export interface EmployeeEnrollmentMetadata {
	payPeriods: number;
	startDate?: number;
	endDate?: number;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionStoreService extends Store<void, PlanSelectionData> {
	constructor(
		private meUserStoreService: MeUserStoreService,
		private planSelectionAPIService: PlanSelectionAPIService
	) {
		super();
	}

	protected retrieve(): Observable<PlanSelectionData> {
		return this.meUserStoreService.get().pipe(
			RxjsUtils.isNotNil(),
			switchMap(() => this.planSelectionAPIService.fetchEnrollment()),
			catchError(() => of(null))
		);
	}

	public effectiveDate(): Observable<string> {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((data) => {
				if (!data?.companyData) return;
				return data.companyData.effectiveDate;
			})
		);
	}

	public isPlanSelectionEnabled(): Observable<boolean> {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((planSelectionData) => {
				// This property is returned only when the integration is Trinet and effectiveDate is sent
				if (planSelectionData.companyData?.isTriNet) return true;

				return this.isEmployeeInsideEnrollment(
					planSelectionData.employeeEnrollment?.metadata ?? null,
				);
			})
		);
	}

	public isEmployeeInsideEnrollment(employeeEnrollmentMetadata?: EmployeeEnrollmentMetadata) {
		if (!employeeEnrollmentMetadata?.startDate) return false;

		const today = new Date();
		const employeeEnrollmentStartDate = getLocalDateFromUTCSeconds(employeeEnrollmentMetadata.startDate);
		// We want users to be able to access PCT on end date as well
		const employeeEnrollmentEndDate = employeeEnrollmentMetadata?.endDate
			? new Date(getLocalDateFromUTCSeconds(employeeEnrollmentMetadata?.endDate).setHours(23, 59, 59, 999))
			: null;

		console.log(`currentDate: ${today}, employeeEnrollmentStartDate: ${employeeEnrollmentStartDate}, employeeEnrollmentEndDate: ${employeeEnrollmentEndDate}`);

		return today >= employeeEnrollmentStartDate &&
			(isNil(employeeEnrollmentEndDate) || today < employeeEnrollmentEndDate);
	}
}
