import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
export interface ProviderEntity {
	address: string;
	phone_numbers: [
		{
			phone: string;
			details: string;
		}
	];
	google_maps_link: string;
}

/**
 * Component will use 100% of the width and height of the parent element
 * @param  providersArr (Input, ProviderEntity) - An array of ProviderEntity objects to display.
 * @param  selectedProvider (Output, EventEmitter, number) - Emits the index of the selected provider to the parent component.
 */
@Component({
	selector: 'app-ribbon-health-providers-browser',
	templateUrl: './ribbon-health-providers-browser.component.html',
	styleUrls: ['./ribbon-health-providers-browser.component.scss'],
})
export class RibbonHealthProvidersBrowserComponent implements OnInit, AfterViewInit {
	readonly START_POSITION = 0;
	public providerLocation: object;
	public providerIndex: number = 0;
	public activeProvider: ProviderEntity;
	public providerPhone: string;

	@Input() providersArr: ProviderEntity[];
	@Output() selectedProvider = new EventEmitter<number>();
	@ViewChild('googleMap', { static: false }) private map: GoogleMap;
	@ViewChild('provider', { static: false }) public provider: ElementRef;

	constructor() {}

	ngOnInit(): void {
		this.activeProvider = this.providersArr[this.providerIndex];
		this.providerPhone = this.getProviderPhoneNumber();
	}

	ngAfterViewInit(): void {
		this.changeMapLocation(this.activeProvider.google_maps_link);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.providersArr.isFirstChange()) return;
		this.activeProvider = changes.providersArr.currentValue[this.providerIndex];
		this.changeMapLocation(this.activeProvider.google_maps_link);
	}

	public changeProvider(moveUp: boolean) {
		this.setActiveProvider(moveUp);
		this.changeMapLocation(this.activeProvider.google_maps_link);
	}

	private setActiveProvider(moveUp: boolean): void {
		moveUp ? this.providerIndex++ : this.providerIndex--;
		this.activeProvider = this.providersArr[this.providerIndex];
		this.selectedProvider.emit(this.providerIndex);
	}

	private changeMapLocation(googleMapsLink: string): void {
		const query = new URLSearchParams(googleMapsLink).get('query');
		if (!query) return;
		const coordinates = query.split(',');
		const lat = parseFloat(coordinates[0]);
		const lng = parseFloat(coordinates[1]);
		this.map.googleMap.setCenter({ lat, lng });
		const marker = new google.maps.marker.AdvancedMarkerElement({
			position: { lat, lng },
			map: this.map.googleMap,
		});
		marker.map = this.map.googleMap;
	}

	getProviderPhoneNumber() {
		const phoneNumberArray = this.activeProvider.phone_numbers;
		if (!phoneNumberArray) return;
		const activePhone =
			phoneNumberArray.find((phoneNumber) => (phoneNumber.details = 'primary')) || phoneNumberArray[0];
		return activePhone.phone;
	}
}
