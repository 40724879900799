import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanType } from '../../../../models/plan-type.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { CommonComponent } from '../../../../utils/components/common-component';
import { MeUserStoreService } from '../../../../services/stores/me-user-store/me-user-store.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { take } from 'rxjs';
import { UserApiService } from '../../../../services/api/user-api/user-api.service';
import { StandardLoadingHandler } from '../../../../utils/loading-handler/standard-loading-handler';
import { UIService } from '../../../../services/ui.service';
import { T } from '@transifex/angular';
import { UserService } from '../../../../services/user.service';
import { createBirthDate } from 'src/app/utils/utils';

@Component({
	selector: 'app-connect-digital-card-form-handler',
	templateUrl: './connect-digital-card-form-handler.component.html',
	styleUrls: ['./connect-digital-card-form-handler.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectDigitalCardFormHandlerComponent extends CommonComponent implements OnInit {
	@Input()
	planType: PlanType;

	@Output()
	unlocked = new EventEmitter<void>();

	@T('Error while unlocking the plan, probably due to incorrect member ID')
	errorWhileUnlocking: string;

	PlanType = PlanType;

	unlockLoadingHandler = new StandardLoadingHandler();

	form = new FormGroup({
		memberId: new FormControl(null, [Validators.required]),
		dateOfBirth: new FormControl(null, []),
		month: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(12)]),
		day: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(31)]),
		year: new FormControl(null, [Validators.required, Validators.max(dayjs().year())]),
		groupId: new FormControl(null, []),
	});

	public isSocialSecurityNumber: boolean = false;

	constructor(
		private meUserStoreService: MeUserStoreService,
		private userApiService: UserApiService,
		private uiService: UIService,
		private userService: UserService
	) {
		super();
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.subsBag.add = this.meUserStoreService
			.get()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe({
				next: (userData) => {
					if (userData.birthday) {
						this.form.patchValue({
							month: dayjs(userData.birthday).month() + 1,
							day: dayjs(userData.birthday).date(),
							year: dayjs(userData.birthday).year(),
						});
					}
				},
			});
	}

	unlock() {
		this.unlockLoadingHandler.start();
		const { memberId, month, day, year } = this.form.value;

		const dateOfBirth = createBirthDate(Number(year), Number(month) - 1, Number(day));

		this.userService.updateUserProfileFromFormData({ dateOfBirth }).subscribe();

		this.userApiService
			.updateContractData({
				memberId,
				birthDate: dateOfBirth.toISOString(),
				planType: this.planType!,
				isSocialSecurityNumber: this.isSocialSecurityNumber,
				groupId: this.form.value.groupId,
			})
			.subscribe({
				next: () => {
					this.unlockLoadingHandler.finish();
					this.unlocked.emit();
				},
				error: () => {
					this.unlockLoadingHandler.finish();
					this.uiService.displayAppMessage(this.errorWhileUnlocking);
				},
			});
	}

	onIsSocialSecurityNumberChange(event: any) {
		this.isSocialSecurityNumber = event.checked;
	}
}
