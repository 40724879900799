<div class="deductible-teaser-container" *ngIf="{
	isConnected: isConnected$ | async,
	isMobile: isMobile$ | async,
	isIndividualData: isIndividualData$ | async,
	outOfPocketDetail: outOfPocketDetail$ | async,
	deductibleDetailsCardData: deductibleDetailsCardData$ | async,
	isNoNetwork: isNoNetwork$ | async,
	hasRibbonInsurancePartner: hasRibbonInsurancePartner$ | async,
	isTalonTpa: isTalonTpa$ | async,
	hasMedicalPlanByKayser: hasMedicalPlanByKayser$ | async,
	shouldDisplayUnlockButton: shouldDisplayUnlockButton$ | async,
} as asyncData">
	<div *ngIf="isDeductibleSyncing" class="loading-indicator-wrapper">
		<app-loader size="small"></app-loader>
	</div>

	<app-healthee-content-slider
		appearance="standard"
		[enableClickScroll]="false"
		[ngClass]="{ disabled: isDeductibleSyncing }"
	>
		<div
			class="deductibles-container"
			[ngClass]="{
				'full-width': asyncData.isNoNetwork,
				'desktop-unlock': asyncData.shouldDisplayUnlockButton
			}"
		>
			<ng-container *ngIf="asyncData.isConnected">
				<!-- Homepage deductibles when not a "no-network" (two cards) -->
				<ng-container *ngIf="!asyncData.isNoNetwork; else noNetworkCardWhenConnected">
					<app-medical-spending-connected-deductible-card
						[hasMyselfBadge]="asyncData.isIndividualData"
						[hasFamilyBadge]="!asyncData.isIndividualData"
						[outOfPocketSpend]="asyncData.outOfPocketDetail?.inNetwork?.spend"
						[outOfPocketTotal]="asyncData.outOfPocketDetail?.inNetwork?.maximum"
						[spend]="asyncData.deductibleDetailsCardData?.inNetwork?.spend"
						[remaining]="asyncData.deductibleDetailsCardData?.inNetwork?.remaining"
						[total]="asyncData.deductibleDetailsCardData?.inNetwork?.maximum"
					></app-medical-spending-connected-deductible-card>

					<app-medical-spending-connected-deductible-card
						[hasMyselfBadge]="asyncData.isIndividualData"
						[hasFamilyBadge]="!asyncData.isIndividualData"
						[isRed]="true"
						[outOfPocketSpend]="asyncData.outOfPocketDetail?.outOfNetwork?.spend"
						[outOfPocketTotal]="asyncData.outOfPocketDetail?.outOfNetwork?.maximum"
						[spend]="asyncData.deductibleDetailsCardData?.outOfNetwork?.spend"
						[remaining]="asyncData.deductibleDetailsCardData?.outOfNetwork?.remaining"
						[total]="asyncData.deductibleDetailsCardData?.outOfNetwork?.maximum"
					></app-medical-spending-connected-deductible-card>
				</ng-container>

				<!-- Homepage deductibles for no-network (one card only) -->
				<ng-template #noNetworkCardWhenConnected>
					<!-- Assuming that when connected, deductible data for no-network come on the 'in-network' object -->
					<app-medical-spending-connected-deductible-card
						[hasMyselfBadge]="asyncData.isIndividualData"
						[hasFamilyBadge]="!asyncData.isIndividualData"
						[outOfPocketSpend]="asyncData.outOfPocketDetail?.inNetwork?.spend"
						[outOfPocketTotal]="asyncData.outOfPocketDetail?.inNetwork?.maximum"
						[spend]="asyncData.deductibleDetailsCardData?.inNetwork?.spend"
						[remaining]="asyncData.deductibleDetailsCardData?.inNetwork?.remaining"
						[total]="asyncData.deductibleDetailsCardData?.inNetwork?.maximum"
						[isNoNetwork]="true"
					></app-medical-spending-connected-deductible-card>
				</ng-template>
			</ng-container>

			<ng-container *ngIf="asyncData.isConnected === false && (deductibles$ | async) as deductibles">

				<!-- Homepage deductibles when not a "no-network" (two cards) -->
				<ng-container *ngIf="!asyncData.isNoNetwork; else noNetworkCardWhenDisconnected">
					<ng-container
						[ngTemplateOutlet]="deductible"
						[ngTemplateOutletContext]="{
							title: HomePageLocalize.deductibleInNetwork,
							value: deductibles.inNetwork,
							color: 'green',
							hasDot: true
						}"
					>
					</ng-container>
					<ng-container
						[ngTemplateOutlet]="deductible"
						[ngTemplateOutletContext]="{
							title: HomePageLocalize.deductibleOutOfNetwork,
							value: deductibles.outNetwork,
							color: 'red',
							hasDot: true
						}"
					>
					</ng-container>

				</ng-container>

				<!-- Homepage deductibles for no-network (one card only) -->
				<ng-template #noNetworkCardWhenDisconnected>
					<div
						[ngTemplateOutlet]="deductible"
						[ngTemplateOutletContext]="{
							title: HomePageLocalize.deductibleNoNetwork,
							value: deductibles.noNetwork,
							color: 'green',
							hasDot: false,
							isFullWidth: true
						}"
					>
					</div>
				</ng-template>
			</ng-container>
		</div>
	</app-healthee-content-slider>

	<ng-container>
		<button
			*ngIf="asyncData.shouldDisplayUnlockButton"
			(click)="openConnectionForm()"
			class="bt connect-button"
		>
			<mat-icon>lock_open</mat-icon>
			{{ 'Unlock full details' | translate }}
		</button>
	</ng-container>

	<ng-template #deductible let-Title="title" let-Value="value" let-Color="color" let-hasDot="hasDot" let-isFullWidth="isFullWidth">
		<div [class]="'deductible ' + Color + ' ' + (isFullWidth ? 'full-width' : '')">
			<div class="deductible-content">
				<div class="deductible-title">
					<div *ngIf="hasDot" class="dot"></div>
					{{ Title | translate }}
				</div>
				<div class="deductible-value">
					<ng-container *ngIf="Value; else deductibleNotAvailable">
						{{ Value }}
					</ng-container>
				</div>
			</div>
			<svg class="deductible-bg" height="40" width="318">
				<circle r="504" cy="447%" cx="50%" />
			</svg>
			<ng-container>
				<button
					*ngIf="
						!asyncData.isConnected &&
						!asyncData.hasMedicalPlanByKayser &&
						(asyncData.hasRibbonInsurancePartner || asyncData.isTalonTpa) &&
						asyncData.isMobile
					"
					(click)="openConnectionForm()"
					class="bt connect-button mobile"
				>
					<mat-icon>lock_open</mat-icon>
					{{ 'Unlock full details' | translate }}
				</button>
			</ng-container>
		</div>
	</ng-template>

	<ng-template #deductibleNotAvailable> N/A </ng-template>

	<ng-template #deductiblesForm>
		<div class="connect-form-modal">
			<p class="connect-form-description">
				{{ connectFormModalDescription }}
			</p>
			<app-connect-deductibles-form-handler
				(connected)="healtheeDialogService.close()"
			></app-connect-deductibles-form-handler>
		</div>
	</ng-template>
</div>
