import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { T } from '@transifex/angular';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Intercom as IntercomMobile } from '@capacitor-community/intercom';

import CFG from '../config/app-config.json';

declare global {
	interface Window {
		Intercom: any;
	}
}

export interface IntercomConversationResponse {
	conversationId: string;
}

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	// DO NOT change text indentation in the T decorator below, or it will break
	// the message the user sees on intercom messenger
	@T(
		'Hi!\
Thanks for reaching out to our support team, let us look into this for you.\
Hang tight, we will reach out to you soon via text message once we have an answer.'
	)
	public readonly zoeWelcomeMessageOnIntercom: string;

	public isEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _intercomHigher$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	private intercomInitialized = false;
	private apiQueue: { method: () => void }[] = [];

	get intercomHigher$() {
		return this._intercomHigher$.asObservable();
	}

	constructor(private http: HttpClient, private platform: Platform) {}

	public init(): void {
		if (this.intercomInitialized) return;

		if (window.Intercom || Capacitor.isNativePlatform()) {
			this.intercomInitialized = true;
			this.processApiQueue();
		} else {
			const interval = setInterval(() => {
				if (window.Intercom || Capacitor.isNativePlatform()) {
					clearInterval(interval);
					this.intercomInitialized = true;
					this.processApiQueue();
				}
			}, 100);
		}
	}

	public async registerUnidentifiedUser() {
		try {
			await this.platform.ready();
		} catch (error) {
			console.log('Error while awaiting platform to get ready.');
			return;
		}

		try {
			if (Capacitor.isNativePlatform()) {
				// Register unidentified user, identified user is registered once logged in (in intercom-messenger component)
				await IntercomMobile.registerUnidentifiedUser();
				console.log('Intercom: Unidentified user is registered successfully');
			}
		} catch (error) {
			console.error('Intercom: Error registering unidentified user:', error);
		}
	}

	private processApiQueue() {
		this.apiQueue.forEach(({ method }) => method());
		this.apiQueue = [];
	}

	private enqueueApiCall(method: () => void) {
		if (this.intercomInitialized) {
			method();
		} else {
			this.apiQueue.push({ method });
		}
	}

	public onShow(): void {
		this.isEnabled$.next(true);
	}

	public onHide(): void {
		this.isEnabled$.next(false);
	}

	public moveIntercomFromPositionPX(state: number): void {
		this._intercomHigher$.next(state);
	}
	public resetIntercomPosition(): void {
		this._intercomHigher$.next(0);
	}

	public initiateIntercomConversation(
		userMessage: string,
		zoeResponse: string, // Zoe response is sent by the client, to support multilanguage
		chatHistoryWithZoe: string = null
	) {
		const url = CFG.apiEndpoints.startIntercomConversation;
		return this.http.post<IntercomConversationResponse>(url, { userMessage, zoeResponse, chatHistoryWithZoe });
	}

	public sendIntercomEvent(eventName: string, data: any) {
		const url = CFG.apiEndpoints.sendIntercomEvent;
		return this.http.post(url, { eventName, data });
	}

	public sendClientEvent(eventName: string, metadata: Record<string, any> = {}): void {
		this.enqueueApiCall(() => {
			if (Capacitor.isNativePlatform()) {
				IntercomMobile.logEvent({ name: eventName, data: metadata });
			} else if (window.Intercom) {
				window.Intercom('trackEvent', eventName, metadata);
			}
		});
	}

	public updateIntercomConversation(
		conversationId: string,
		userMessage: string,
		zoeResponse: string,
		chatHistoryWithZoe: string = null
	) {
		const url = CFG.apiEndpoints.updateIntercomConversation;
		return this.http.post<IntercomConversationResponse>(url, {
			conversationId,
			userMessage,
			zoeResponse,
			chatHistoryWithZoe,
		});
	}

	public openMessenger(): void {
		this.enqueueApiCall(() => {
			if (Capacitor.isNativePlatform()) {
				IntercomMobile.displayMessenger();
			} else if (window.Intercom) {
				window.Intercom('show');
			}
		});
	}

	public openConversation(conversationId: string): void {
		this.enqueueApiCall(() => {
			if (Capacitor.isNativePlatform()) {
				IntercomMobile.displayMessenger();
			} else if (window.Intercom) {
				window.Intercom('showConversation', conversationId);
			}
		});
	}

	public openArticle(articleId: string): void {
		this.enqueueApiCall(() => {
			if (Capacitor.isNativePlatform()) {
				IntercomMobile.displayArticle({ articleId });
			} else if (window.Intercom) {
				window.Intercom('showArticle', articleId);
			}
		});
	}
}
