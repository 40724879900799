import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Observable, combineLatest, forkJoin, map, shareReplay, take } from 'rxjs';
import { UserPlanDataStoreService } from '../stores/user-plan-data-store/user-plan-data-store.service';
import { UserData } from '../../models/user-data';
import { PlanData } from '../../models/plan-data.model';
import { RibbonEntitiesStoreService } from '../stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { BlackhawkCardTemplates, blackhawkNetworkNames } from './templates/blackhawk/blackhawk';
import { Benefit } from '../../models/benefits.model';
import { deepCopy, getNumberValuesFromDollarAndPercent } from '../../utils/utils';
import { isNil } from 'src/app/utils/is/is-nil';
import { BenefitStoreService } from '../stores/benefit-store/benefit-store.service';
import { LoggerService } from '../logger.service';
import { CompanyStoreService } from '../stores/company-store/company-store.service';
import { ICompany } from '../../models/company.model';
import { CharterSchoolCardTemplates } from './templates/charter-school/charter-school';
import { ServicesIds, RxServicesIds, networkNames } from './templates/shared';
import { RxjsUtils } from '../../utils/rxjs';

export enum CUSTOM_CARD_TYPES {
	BLACKHAWK = 'blackhawk',
	CHARTERSCHOOL = 'charterschool',
}

@Injectable({
	providedIn: 'root',
})
export class DigitalCardService {
	private userData$: Observable<UserData>;
	private planData$: Observable<PlanData>;
	private contractRibbon$: Observable<any[]>;
	private template$: Observable<any>;
	private benefits$: Observable<Array<Benefit & { cost: number[] }>>;
	private company$: Observable<ICompany>;
	private customCardType: CUSTOM_CARD_TYPES;

	constructor(
		private userService: UserService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private benefitStoreService: BenefitStoreService,
		private logger: LoggerService,
		private companyStoreService: CompanyStoreService
	) {
		this.userData$ = this.userService.userData$;
		this.planData$ = this.userPlanDataStoreService.get();
		this.contractRibbon$ = this.ribbonEntitiesStoreService.getContractRibbon();
		this.company$ = this.companyStoreService.get();

		this.fetchBenefits();
		this.fetchTemplateAndPopulate();
	}

	fetchBenefits() {
		const allServicesIds = [...Object.values(ServicesIds), ...Object.values(RxServicesIds)];
		const observables = allServicesIds.map((serviceId) =>
			this.benefitStoreService.get(serviceId as any).pipe(take(2))
		);
		this.benefits$ = forkJoin(observables).pipe(
			map((benefits) => {
				return benefits
					.filter((benefit) => !isNil(benefit))
					.map((benefit) => ({
						...benefit,
						cost: getNumberValuesFromDollarAndPercent(benefit.inNetwork),
					}))
					.filter((benefit) => !(isNil(benefit.cost[0]) && isNil(benefit.cost[1])));
			}),
			shareReplay(1)
		);
	}

	fetchTemplateAndPopulate() {
		this.template$ = combineLatest([
			this.userData$,
			this.planData$.pipe(RxjsUtils.isNotNil()),
			this.contractRibbon$,
			this.benefits$,
			this.company$.pipe(RxjsUtils.isNotNil()),
		]).pipe(
			map(([userData, planData, contractRibbon, benefits, company]) => {
				if (this.customCardType == CUSTOM_CARD_TYPES.BLACKHAWK) {
					return this.getBlackhawkTemplate(userData, planData, contractRibbon, benefits, company);
				} else if (this.customCardType == CUSTOM_CARD_TYPES.CHARTERSCHOOL) {
					return this.getCharterSchoolTemplate(userData, planData, benefits);
				}
			}),
			shareReplay(1)
		);
	}

	getCharterSchoolTemplate(userData, planData, benefits) {
		if (!planData.contract?.networkStructure) {
			this.logger.error('DigitalCardService', {
				func: 'DigitalCardService.fetchTemplateAndPopulate',
				msg: `No networkStructure field for contract ${planData.contract._id}`,
				contractId: planData.contract._id,
				userId: userData.uid,
			});
			throw new Error(`No networkStructure field for contract ${planData.contract._id}, userId: ${userData.uid}`);
		}
		const templateKey = this.isNetworkRBP(planData) ? networkNames.RBP : networkNames.OTHER;

		return this.getPopulatedTemplate(
			CUSTOM_CARD_TYPES.CHARTERSCHOOL,
			CharterSchoolCardTemplates,
			templateKey,
			userData,
			planData,
			benefits,
			templateKey
		);
	}

	getBlackhawkTemplate(userData, planData, contractRibbon, benefits, company) {
		const networkName = this.getNetworkNameOrStructure(contractRibbon, planData.contract.ribbon, planData);

		const specialTemplateKey = this.getSpecialTemplateKey(company);

		if (specialTemplateKey) {
			return this.getPopulatedTemplate(
				CUSTOM_CARD_TYPES.BLACKHAWK,
				BlackhawkCardTemplates,
				specialTemplateKey,
				userData,
				planData,
				benefits,
				networkName
			);
		}

		if (!networkName) {
			this.logger.error('DigitalCardService', {
				func: 'DigitalCardService.fetchTemplateAndPopulate',
				msg: `No network name found for contract ${planData.contract._id}`,
				contractId: planData.contract._id,
				userId: userData.uid,
			});
			throw new Error(`No network name found for contract ${planData.contract._id}, userId: ${userData.uid}`);
		}

		const preAuthVendor = company.preAuthVendor;
		if (!preAuthVendor) {
			this.logger.error('DigitalCardService', {
				func: 'DigitalCardService.fetchTemplateAndPopulate',
				msg: `No preAuthVendor found for company ${company.name}`,
				userId: userData.uid,
			});
			throw new Error(`No preAuthVendor name found for company ${company.name}, userId: ${userData.uid}`);
		}
		const templateKey = `${networkName}_${preAuthVendor}`.toLowerCase();

		if (!BlackhawkCardTemplates[templateKey]) {
			this.logger.error('DigitalCardService', {
				func: 'DigitalCardService.fetchTemplateAndPopulate',
				msg: `No template found for ${templateKey}`,
				userId: userData.uid,
				companyName: company.name,
			});
			throw new Error(`No template found for ${templateKey}, company: ${company.name}, userId: ${userData.uid}`);
		}

		return this.getPopulatedTemplate(
			CUSTOM_CARD_TYPES.BLACKHAWK,
			BlackhawkCardTemplates,
			templateKey,
			userData,
			planData,
			benefits,
			networkName
		);
	}

	private getSpecialTemplateKey(company) {
		//TODO: Add a dictionary for the special templates
		if (company._id === '67c9aec5579db67abce6a6b1') {
			return "healthsmart_medwatch_with_bmr";
		}
	}

	private getPopulatedTemplate(templateType, templates, templateKey, userData, planData, benefits, networkName) {
		const template = deepCopy(templates[templateKey]);

		template.front = {
			name: `${userData.firstName} ${userData.lastName}`,
			memberId: (userData as any).contractMemberIds?.medical?.memberId,
			groupId: planData.contract?.groupNumber,
			effectiveDate: planData.contract?.effectiveDate ? new Date(planData.contract?.effectiveDate * 1000) : null,
			dependents: userData.dependents,
			deductible: {
				individual: planData.contract?.deductible?.individual,
				family: planData.contract?.deductible?.family,
			},
			outOfPocketMax: {
				individual: planData.contract?.outOfPocketMax?.individual,
				family: planData.contract?.outOfPocketMax?.family,
			},
			benefits: benefits,
			networkName: networkName,
			planName: planData.contract?.name,
			upperLeftLogo: this.getUpperLeftLogo(templateType),
			upperRightLogo: this.getUpperRightLogo(templateType, networkName),
		};

		template.customCardType = this.customCardType;

		return template;
	}

	getUpperLeftLogo(templateType: CUSTOM_CARD_TYPES) {
		let logo;
		if (templateType == CUSTOM_CARD_TYPES.BLACKHAWK) {
			logo = 'blackhawk_logo';
		} else if (templateType == CUSTOM_CARD_TYPES.CHARTERSCHOOL) {
			logo = 'myqhealth';
		}
		return logo;
	}

	getUpperRightLogo(templateType: CUSTOM_CARD_TYPES, networkName: string) {
		let logo;

		if (templateType == CUSTOM_CARD_TYPES.BLACKHAWK) {
			if (networkName == networkNames.FRONT_PATH) {
				logo = 'frontpath_right';
			} else if (networkName !== networkNames.RBP) {
				logo = networkName.toLowerCase().replace(/\s+/g, '');
			}
		} else if (templateType == CUSTOM_CARD_TYPES.CHARTERSCHOOL) {
			logo = 'allegiance';
		}

		return logo;
	}

	getNetworkNameOrStructure(contractRibbon, ribbonId: string, planData: PlanData) {
		let network;

		if (ribbonId) {
			const contractRibbonDocument = contractRibbon.find((ribbon) => ribbon._id === ribbonId);
			network = Object.values(blackhawkNetworkNames).find((network) =>
				contractRibbonDocument?.display_name?.toLowerCase().replace(/\s+/g, '').includes(network?.toLowerCase())
			);
		}

		if (!network) {
			//Incase its RBP there is not network and the networkStructure should be populated
			network = this.isNetworkRBP(planData) ? networkNames.RBP : null;
		}

		if (network) {
			return network;
		}

		return undefined;
	}

	isNetworkRBP(planData: PlanData) {
		return planData.contract?.networkStructure?.toLowerCase().replace(/\s+/g, '') ==
			networkNames.RBP.toLowerCase().replace(/\s+/g, '')
			? true
			: false;
	}

	getCustomCardType(company?: any): CUSTOM_CARD_TYPES {
		//TODO: ask Mariam about !!company?.affiliatedCompanies
		if (this.customCardType) {
			this.logger.info(
				`DigitalCardService.getCustomCardType - Using cached custom card type: ${this.customCardType} for company: ${company?._id}`
			);
			return this.customCardType;
		}
		if (!company) {
			this.logger.info(
				`DigitalCardService.getCustomCardType - company is undefined, returnning undefined custom card type.`
			);
			return;
		}

		const blackhawkAffiliatedCompany = (company as any)?.affiliatedCompanies?.find(
			(comp) =>
				comp?.name?.toLowerCase().replace(/\s+/g, '') == CUSTOM_CARD_TYPES.BLACKHAWK && comp?.type == 'tpa'
		);
		if (blackhawkAffiliatedCompany) {
			this.logger.info(
				`DigitalCardService.getCustomCardType - Using custom card type: ${CUSTOM_CARD_TYPES.BLACKHAWK} for company: ${company?._id}`
			);
			return CUSTOM_CARD_TYPES.BLACKHAWK;
		}

		if (company?.name?.toLowerCase().replace(/\s+/g, '').includes(CUSTOM_CARD_TYPES.CHARTERSCHOOL)) {
			this.logger.info(
				`DigitalCardService.getCustomCardType - Using custom card type: ${CUSTOM_CARD_TYPES.CHARTERSCHOOL} for company: ${company?._id}`
			);
			return CUSTOM_CARD_TYPES.CHARTERSCHOOL;
		}

		this.logger.info(
			`DigitalCardService.getCustomCardType - No custom card type found for company: ${company?._id}`
		);
		return undefined;
	}

	getTemplate(customCardType: CUSTOM_CARD_TYPES): Observable<any> {
		this.customCardType = customCardType;
		return this.template$;
	}
}
