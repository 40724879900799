import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip/tooltip.component';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
    @Input('appTooltip') template: TemplateRef<any>;

    private static sharedOverlayRef: OverlayRef | null = null;
    private static activeTooltip: TooltipDirective | null = null;

    @Input() tooltipWidth: number | string = 'auto';
    @Input() tooltipAutoHide: boolean = true;
    @Input() tooltipPositionX: 'start' | 'center' | 'end' = 'center';
    @Input() tooltipPositionY: 'top' | 'center' | 'bottom' = 'top';
    @Input() tooltipOffsetX: number = 0;
    @Input() tooltipOffsetY: number = 5;

    constructor(private elementRef: ElementRef, private overlay: Overlay) {}

    ngOnInit(): void {
        if (!TooltipDirective.sharedOverlayRef) {
            TooltipDirective.sharedOverlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().flexibleConnectedTo(this.elementRef),
                width: this.tooltipWidth,
                scrollStrategy: this.overlay.scrollStrategies.close(),
            });
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.showTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        if (this.tooltipAutoHide) {
            this.hideTooltip();
        }
    }

	private showTooltip() {
		if (TooltipDirective.activeTooltip && TooltipDirective.activeTooltip !== this) {
			TooltipDirective.activeTooltip.hideTooltip();
		}

		TooltipDirective.activeTooltip = this;

		const overlayRef = TooltipDirective.sharedOverlayRef;
		if (overlayRef) {
			const positionStrategy = this.overlay
				.position()
				.flexibleConnectedTo(this.elementRef)
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: this.tooltipPositionX,
						overlayY: this.tooltipPositionY,
						offsetX: this.tooltipOffsetX,
						offsetY: this.tooltipOffsetY,
					},
				])
				.withPush(false);

			overlayRef.updatePositionStrategy(positionStrategy);

			if (!overlayRef.hasAttached()) {
				const tooltipRef = overlayRef.attach(new ComponentPortal(TooltipComponent));
				tooltipRef.instance.tooltip = this.template;
				tooltipRef.instance.autoHide = this.tooltipAutoHide;
				tooltipRef.changeDetectorRef.detectChanges();
			}
		}
	}

    private hideTooltip() {
        const overlayRef = TooltipDirective.sharedOverlayRef;
        if (overlayRef && overlayRef.hasAttached()) {
            overlayRef.detach();
        }

        if (TooltipDirective.activeTooltip === this) {
            TooltipDirective.activeTooltip = null;
        }
    }

    ngOnDestroy(): void {
        if (TooltipDirective.activeTooltip === this) {
            this.hideTooltip();
        }
    }
}
