/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BenefitWithCost } from '../services/digital-card/templates/shared';

/**
 * Resolves the correct host, either by the current window url,
 * or from the override settings set in environment.xxx.ts file.
 * See readme.md and notes inside environment.xxx.ts for more details.
 */
export function resolveHost() {
	if (isHostMPI()) {
		return `${environment.mpiServer.hostname}:${environment.mpiServer.port}`;
	} else if (environment.useAltServer) {
		return `${environment.altServer.hostname}:${environment.altServer.port}`;
	} else {
		return `${window.location.protocol}://${window.location.hostname}:${environment.serverPort}`;
	}
}

// Temporary hack until we will have a local environment file.
export function isLocal() {
	return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
}

export function isHostMPI() {
	return window.location.hostname.startsWith('mpi-app');
}

export function isAppMPI() {
	return window.location.search.includes('idp=mpi');
}

export function isAppEmbeddedInIframe() {
	return window.self !== window.top;
}

export function returnEnvironmentField(fieldName) {
	return environment[fieldName];
}

export function resolveUnleashHostName() {
	return environment.unleashURL;
}
/**
 * Encodes file data in base64
 * @param  {File} file
 */
export function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		if (!file) {
			reject('file object is null');
		}

		const reader = new FileReader();

		reader.onloadend = function () {
			resolve({ res: reader.result, name: file.name });
		};
		reader.readAsDataURL(file);
	});
}

/** wait for a defined time in milliseconds.
 * @returns Promise. Use with await or by chaining a .then() block
 * @param  {number} timeInMS - time in milliseconds
 */
export async function delay(timeInMS: number) {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, timeInMS);
	});
}

/** wait for a defined time in milliseconds, and log count.
 * @returns Promise. Use with await or by chaining a .then() block
 * @param  {number} timeInMS - time in milliseconds
 */
export async function delayAndLogCount(timeInMS: number, intervalInMs: number) {
	let interval = null,
		pulses = 0;
	return new Promise<void>((resolve) => {
		interval = setInterval(() => {
			pulses++;
			const lapsed = intervalInMs * pulses;
			console.log(`Countdown ${timeInMS - lapsed}`);

			if (lapsed > timeInMS) {
				clearInterval(interval);
				resolve();
			}
		}, intervalInMs);
	});
}

export function withTimeout<T>(promise: Promise<T>, ms: number): Promise<T> {
	const timeout = new Promise<never>((_, reject) => setTimeout(() => reject(new Error('Promise timed out')), ms));
	return Promise.race([promise, timeout]);
}

export type KeyValuePair = { key: string; value: any };

/**
 *
 * @param {string} url - original url (w/params) to fetch
 * @param {KeyValuePair[]} paramsArray - params array of key value pairs
 * @returns updated url with params instead of placeholders
 */
export function insertParamsToUrl(url: string, paramsArray: KeyValuePair[]): string {
	for (const param of paramsArray) {
		url = url.replace(`:${param.key}`, param.value);
	}
	return url;
}

/**
 *
 * @param {string} url - original url (w/params), for example: http://localhost:4200/gallery?primary=61d2998942f33e4082a17016&hazar=true&go=888
 * @returns  {KeyValuePair[]} paramsArray - params array of key value pairs
 */
export function getParamsFromUrl(url: string): KeyValuePair[] {
	const paramsArray: KeyValuePair[] = [];

	const urlObject = new URL(url);
	urlObject.searchParams.forEach((value, key) => paramsArray.push({ key, value }));

	return paramsArray;
}

/**
 *
 * @param {string} url - full url to clean
 */
export function getUrlWoParams(url: string): string {
	url = url.split('?')[0];
	return url;
}

export function removeSegmentsFromRoute(fullRoute: string, numberOfSegmentsToRemove: number) {
	const urlSegments = fullRoute.split('/');
	const numberOfSegments = urlSegments.length;

	if (numberOfSegmentsToRemove > numberOfSegments)
		throw new Error(
			`Cannot remove ${numberOfSegmentsToRemove} segments
			from a route with only ${numberOfSegments} segments.`
		);

	const indexToStartRemoving = numberOfSegments - numberOfSegmentsToRemove;

	urlSegments.splice(indexToStartRemoving, numberOfSegmentsToRemove);
	return urlSegments.join('/');
}

/** Method to replace styles/template files according to environment.
 * This is a workaround until this angular/cli issue is fixed:
 * https://github.com/angular/angular-cli/issues/11451
 * @param  {string} webFilename - normal web css/html file
 * @param  {string} ionicFilename - alternative ionic css/html file
 */
export function useIonicFile(webFilename: string, ionicFilename: string) {
	return isIonic() ? ionicFilename : webFilename;
}

export function isIonic() {
	return environment.environmentType === 'ionic';
}

export function getPlatformId(): 'web' | 'ionic' {
	return environment.environmentType === 'ionic' ? 'ionic' : 'web';
}

export function isProduction() {
	return environment.production || environment.envName === 'ionic-prod';
}

export function extractActivatedRouteData(activatedRoute: ActivatedRoute) {
	let child = activatedRoute.firstChild;
	while (child) {
		if (child.firstChild) {
			child = child.firstChild;
		} else if (child.snapshot.data) {
			return child.snapshot.data;
		} else {
			return null;
		}
	}
	return null;
}

export function shuffleArray(array: Array<any>): Array<any> {
	let currentIndex = array.length,
		randomIndex;

	while (currentIndex != 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

export function getRandomElementFromArray(array) {
	return array[Math.floor(Math.random() * array.length)];
}

export function extractPropertyFromActivatedRouteData(activatedRoute: ActivatedRoute, propertyName: string) {
	let child = activatedRoute.firstChild;
	while (child) {
		if (child.firstChild) {
			child = child.firstChild;
		} else if (child.snapshot.data && child.snapshot.data[propertyName]) {
			return child.snapshot.data[propertyName];
		} else {
			return null;
		}
	}
	return null;
}

/** Checks if value is not null and is not undefined
 * @param  {T} value - value to check
 * @returns ***true*** if value is not null AND is not undefined, otherwise - returns ***false***
 */
export function isNotNullOrUndefined<T>(value: T): value is NonNullable<T> {
	return value != null;
}

/** Checks if value is null
 * @param  {T} value - value to check
 * @returns ***true*** if value is not null, otherwise - returns ***false***
 */
export function isNotNull<T>(value: T): value is NonNullable<T> {
	return value !== null;
}

/** RxJs custom operator to skip null and undefined values
 */
export const skipNullAndUndefined =
	() =>
	<T>(source: Observable<T>): Observable<T> =>
		source.pipe(filter(isNotNullOrUndefined));

/** RxJs custom operator to skip null values
 */
export const skipNull =
	() =>
	<T>(source: Observable<T>): Observable<T> =>
		source.pipe(filter(isNotNull));

/** RxJs custom operator to skip false values
 */
export const skipFalse =
	() =>
	(source: Observable<boolean>): Observable<boolean> =>
		source.pipe(filter((value) => value !== false));

/** Counts the number of elements in a typescript Enum */
export function enumElementCount(enumName: any): number {
	let count = 0;
	for (const item in enumName) {
		if (isNaN(Number(item))) count++;
	}
	return count;
}

export function enumFirstElement(enumName: any): number {
	return enumName[Object.keys(enumName)[0]];
}

export function enumFirstElementValue(enumName: any): any {
	return Object.keys(enumName)[0];
}

export interface RandomStringOptions {
	skipUppercase?: boolean;
	skipLowerCase?: boolean;
	skipNumbers?: boolean;
}
export function randomString(stringLength: number, options?: RandomStringOptions) {
	const UPPER_CASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const LOWER_CASE = 'abcdefghijklmnopqrstuvwxyz';
	const NUMBERS = '0123456789';

	let charSource = '';
	let result = '';

	if (!options?.skipUppercase) charSource += UPPER_CASE;
	if (!options?.skipLowerCase) charSource += LOWER_CASE;
	if (!options?.skipNumbers) charSource += NUMBERS;

	const charactersLength = charSource.length;

	for (let i = 0; i < stringLength; i++) {
		result += charSource.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function getBrowserName(): string {
	const agent = window.navigator.userAgent.toLowerCase();
	switch (true) {
		case agent.indexOf('edge') > -1:
			return 'edge';
		case agent.indexOf('opr') > -1 && !!(<any>window).opr:
			return 'opera';
		case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
			return 'chrome';
		case agent.indexOf('trident') > -1:
			return 'ie';
		case agent.indexOf('firefox') > -1:
			return 'firefox';
		case agent.indexOf('safari') > -1:
			return 'safari';
		default:
			return 'other';
	}
}

export function isEmail(email: string): boolean {
	const emailPattern = /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/;
	return emailPattern.test(email);
}

export function getUTCDateWithoutTime(date: Date) {
	const dateOnlyUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	const dateOnlyObject = new Date(dateOnlyUTC);
	return dateOnlyObject;
}

export function setUTCDateAsLocal(inputDate: Date) {
	const date = new Date(inputDate);
	const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
	return adjustedDate;
}

export function getUTCDateWithoutTimeISOString(date: Date) {
	return getUTCDateWithoutTime(date).toISOString();
}
/**
* ###Month is zero-based (0-11). a.k.a JS month ### 
* Creates a UTC Date object from birth date components. 
* @param {number} year - Full year (e.g., 1990)
* @param {number} month - Month (0-11), day - Day of month (1-31)
*/
export function createBirthDate(year: number, month: number, day: number): Date {
	const utcDate = Date.UTC(year, month, day);
	return new Date(utcDate);
}

export function getLocalDateFromUTCSeconds(epochSeconds: number) {
	const date = new Date(epochSeconds * 1000); // Convert timestamp to Date
	return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()); // Use local time zone components
}

export function capitalizeFirstLetter(str: string) {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function invokePhoneDialer(phoneNumber: string) {
	document.location.href = 'tel:' + phoneNumber;
}

export function getDomainNameFromUrl(url: string) {
	try {
		const { hostname } = new URL(url);
		const hostnameParts = hostname.split('.');
		const domainName = hostnameParts[1];

		return domainName;
	} catch (err) {
		return '';
	}
}

export function addScriptToIndexHtmlHead({
	props,
}: {
	props: {
		id: string;
		src: string;
		// eslint-disable-next-line @typescript-eslint/ban-types
		onLoadCallback: Function;
		// eslint-disable-next-line @typescript-eslint/ban-types
		onErrorCallback: Function;
	};
}) {
	const scriptElement = this.document.head.querySelector(`#${props.id}`);

	if (scriptElement) return;

	const script = this.renderer2.createElement('script');

	script.type = 'text/javascript';
	script.id = props.id;
	script.src = props.src;
	script.async = false;
	script.onload = props.onLoadCallback;
	script.onerror = props.onErrorCallback;
	this.renderer2.appendChild(this.document.head, script);
}

export function toByKey<T>(array: T[] = [], key: string | number): { [key: string | number]: T } {
	return array.reduce((result: { [key: string | number]: T }, currentObject: T) => {
		result[currentObject[key]] = currentObject;
		return result;
	}, {});
}

export const formatDecimalNumber = (num: number | undefined, notApplicableResult: string = 'N/A'): string => {
	if (num === undefined || num === null) return notApplicableResult;
	if (num % 1 === 0) return `$${num.toLocaleString()}`;
	return `$${num.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})}`;
};

export const getNumberValuesFromDollarAndPercent = (str) => {
	const numbers = [null, null];
	if (!str) return numbers;
	const splitSpaces = str.split(' ');

	for (const str of splitSpaces) {
		if (str.indexOf('$') > -1) {
			numbers[0] = parseFloat(str.match(/(\d+)/));
		}
		if (str.indexOf('%') > -1) {
			numbers[1] = parseFloat(str.match(/(\d+)/));
		}
	}

	return numbers;
};

export const timeoutReturnFalse = (ms) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(false);
		}, ms);
	});
};

export const toTitleCase = (sentence) => {
	return sentence.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export function isToday(date: Date): boolean {
	const today = new Date();
	const inputDate = new Date(date);

	return (
		inputDate.getDate() === today.getDate() &&
		inputDate.getMonth() === today.getMonth() &&
		inputDate.getFullYear() === today.getFullYear()
	);
}

export function hasTrulyValue(obj: any): boolean {
	if (!obj) return false;
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			if (obj[key]) {
				return true;
			}
		}
	}
	return false;
}

export function deepCopy(object: any): any {
	return JSON.parse(JSON.stringify(object));
}

export function isObjectEmpty(obj: any): boolean {
	return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function getBenefitCostValue(benefit: BenefitWithCost): string {
	if (!benefit?.cost) return undefined;

	if (benefit.cost[0]) {
		return `$${benefit.cost[0]}`;
	} else if (!benefit.cost[0] && benefit.cost[1]) {
		if (benefit.cost[1] === 100) {
			return '$0';
		} else {
			return `${benefit.cost[1]}%`;
		}
	}
}

export function getKeyByValue(value, myEnum) {
	for (const key in myEnum) {
		if (myEnum[key] === value) {
			return key;
		}
	}
	return null;
}

export function removeDuplicatsFromArray(array: any[]): any[] {
	return [...new Set(array)];
}

export function formatToTwoDecimals(value: number): string {
	const valueString = value.toString();
	if (valueString.includes('.') && valueString.split('.')[1].length === 1) {
		return value.toFixed(2);
	}
	return valueString;
}
