export interface MinimalSupportedNativeVersion {
	// App version and build number should always match the version of the released
	// native app IN THE STORES. Since AppFlow releases reach production faster,
	// you must target the currently released native version in STORE, otherwise the user
	// will always get a notification that there's a newer version, and keeps being
	// directed to Google Play/App Store to downoad the newer version, where in fact,
	// there's no new version available that can be installed.
	version: string;
	build: number;

	// WARNING: Use isMandatory = true with caution!!
	// When isMandatory = true, app will no longer be usable until
	// the native app is updated through the app stores
	updateIsMandatory: boolean;
}

export const minimalSupportedAndroidAppVersion: MinimalSupportedNativeVersion = {
	version: '2.14.2',
	build: 132,
	updateIsMandatory: true,
};

export const minimalSupportediOSAppVersion: MinimalSupportedNativeVersion = {
	version: '2.14.2',
	build: 132,
	updateIsMandatory: true,
};
