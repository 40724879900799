<app-mobile-action-bar [showBottomBorder]="false"></app-mobile-action-bar>
<div [ngSwitch]="showDefaultLayout$ | async" class="home-page-wrapper">
	<div *ngIf="showNewAppBanner && !isIonic" class="new-app-banner">
		<span class="new-app-banner__text">
			{{
				'A fresh new upgrade is here! Enjoy effortless benefit navigation in our brand new app'
					| translate
			}}
		</span>
		<ripple-button
			label="{{ 'Switch to new app' | translate }}"
			hierarchy="primary"
			class="new-app-banner__button"
			size="sm"
			(click)="onRedirectToNewApp()"
		></ripple-button>
	</div>
	<div *ngSwitchCase="true" class="home-page-teaser-list">
		<div class="welcome-message-container" [style.order]="0">
			<span class="title">{{ 'Hi ' | translate }} {{ (firstName$ | async | titlecase) + '!' }}</span>
			<span class="subtitle">{{ 'How can we help you today?' | translate }}</span>
		</div>

		<ng-container *ngIf="teasersOrder$ | async as order">
			<app-home-page-teaser [style.order]="order.quickAccess" [class.teaser-border-bottom]="isMobile$ | async">
				<app-home-page-teaser-header *ngIf="(isMobile$ | async) === false">
					<div title>{{ 'Quick access' | translate }}</div>
				</app-home-page-teaser-header>
				<app-home-page-teaser-body class="quick-access">
					<app-quick-access></app-quick-access>
				</app-home-page-teaser-body>
			</app-home-page-teaser>

			<app-home-page-teaser
				*ngIf="showTelehealthTeaser$ | async"
				[style.order]="order.telehealth"
				class="telehealth-teaser"
			>
				<app-home-page-teaser-body>
					<app-telehealth-teaser></app-telehealth-teaser>
				</app-home-page-teaser-body>
			</app-home-page-teaser>

			<!-- My Deductibles card -->
			<app-home-page-teaser
				*ngIf="(showDeductibleTeaser$ | async) && hasParticipantAccDataFeature"
				[style.order]="order.deductible"
				class="deductible-teaser"
			>
				<!-- Header for the my deductibles card -->
				<app-home-page-teaser-header>
					<div title>
						{{ 'Your deductible' | translate }}

						<div *ngIf="isHRAPlan$ | async" class="deductible-info-icon-wrapper">
							<ripple-icon
								name="infoCircle"
								[size]="RIPPLE_ICON_SIZE.size12"
								[rippleTooltipWithTouch]="HRAPlanTooltipContent"
								[tooltipAutoHide]="false"
								[tooltipTitleOnMobile]="'Note' | translate"
								tooltipPositionX="start"
								tooltipPositionY="bottom"
								tooltipOffsetX="10"
								tooltipOffsetY="10"
							></ripple-icon>
						</div>
					</div>
					<a routerLink="/health-profile/medical-spendings" link>{{ 'See all' | translate }}</a>
					<app-deductible-sync subTitle (isSyncing)="onDeductibleSyncStatusChanged($event)">
					</app-deductible-sync>
				</app-home-page-teaser-header>

				<!-- The actual in/out-network 2 cards, or 1 no-network card -->
				<app-home-page-teaser-body>
					<app-deductible-teaser [isDeductibleSyncing]="isDeductibleSyncing"></app-deductible-teaser>
				</app-home-page-teaser-body>
			</app-home-page-teaser>

			<app-home-page-teaser
				class="health-cards-teaser"
				*ngIf="isHomePageHealthCardTeaserAvailable$ | async"
				[style.order]="order.healthCards"
			>
				<app-home-page-teaser-body>
					<app-health-cards-teaser
						(showCardsClick)="router.navigate(['health-profile'])"
					></app-health-cards-teaser>
				</app-home-page-teaser-body>
			</app-home-page-teaser>

			<ng-container
				*ngIf="{
					hasContract: hasContract$ | async,
					selectedBenefits: selectedBenefits$ | async
				} as state"
			>
				<app-home-page-teaser
					*ngIf="state.hasContract && state.selectedBenefits?.length > 0"
					[style.order]="order.selectedBenefits"
					class="selected-benefits-teaser"
				>
					<app-home-page-teaser-header>
						<div title>{{ 'Selected benefits' | translate }}</div>
						<div subTitle>{{ 'Uncover benefits for a Healthee life' | translate }}</div>
						<a
							(click)="onSeeAllBenefitsClick()"
							[routerLink]="
								(languageService.isSpanish$ | async) === false ? '/coverage-info' : '/gallery'
							"
							fragment="Benefits Teaser"
							link
							>{{ 'See all' | translate }}</a
						>
					</app-home-page-teaser-header>
					<app-home-page-teaser-body>
						<app-selected-benefits-teaser [selectedServices]="state.selectedBenefits">
						</app-selected-benefits-teaser>
					</app-home-page-teaser-body>
				</app-home-page-teaser>
			</ng-container>

			<app-home-page-teaser
				*ngIf="showPreventiveCareTeaser && hasWellnessTrackerFeature"
				[style.order]="order.preventiveCare"
				class="preventive-care-teaser"
			>
				<app-home-page-teaser-body>
					<app-preventive-care-teaser
						[showInitTeaser]="(isPreventiveCareInit$ | async) !== true"
						source="Home page"
					></app-preventive-care-teaser>
				</app-home-page-teaser-body>
			</app-home-page-teaser>

			<ng-container *ngIf="myCareTeamHomePageTeaserEnabled">
				<app-home-page-teaser
					*ngIf="hasFavoriteProviders$ | async; else myCareTeamEmptyState"
					[style.order]="order.myCareTeam"
					class="my-care-team-teaser"
				>
					<app-home-page-teaser-header>
						<div title>{{ 'My Care Team' | translate }}</div>
						<div subTitle>{{ 'Access your providers anytime, anywhere' | translate }}</div>
						<a (click)="onAllProvidersClick()" [routerLink]="['/care-and-costs/providers']" link>{{
							'Find Providers' | translate
						}}</a>
					</app-home-page-teaser-header>
					<app-home-page-teaser-body>
						<app-my-care-team-teaser></app-my-care-team-teaser>
					</app-home-page-teaser-body>
				</app-home-page-teaser>

				<ng-template #myCareTeamEmptyState>
					<app-home-page-teaser
						[style.order]="order.myCareTeam"
						class="my-care-team-empty-state"
						appearance="outlined"
						[routerLink]="['/care-and-costs/providers']"
					>
						<app-my-care-team-empty-state-teaser></app-my-care-team-empty-state-teaser>
					</app-home-page-teaser>
				</ng-template>
			</ng-container>

			<app-home-page-teaser
				*ngIf="isPlanSelectionEnabled$ | async"
				[style.order]="order.planSelection"
				class="plan-selection"
				appearance="outlined"
				[routerLink]="[planSelectionPath]"
			>
				<app-plan-selection-teaser></app-plan-selection-teaser>
			</app-home-page-teaser>

			<app-home-page-teaser [style.order]="order.quantumSupport" *ngIf="isIonic && showQuantumSupport">
				<app-home-page-teaser-body>
					<app-quantum-support></app-quantum-support>
				</app-home-page-teaser-body>
			</app-home-page-teaser>
		</ng-container>
	</div>
	<div class="non-eligible-layout-container" *ngSwitchCase="false">
		<div class="welcome-message-container" [style.order]="0">
			<span class="title">{{ 'Hi ' | translate }} {{ (firstName$ | async | titlecase) + '!' }}</span>
			<span class="subtitle">{{ 'How can we help you today?' | translate }}</span>
		</div>
		<app-non-eligible-layout></app-non-eligible-layout>
	</div>
</div>

<ng-template #HRAPlanTooltipContent>
	{{ 'Your HRA can help cover costs for doctor visits, prescriptions, and more. Check your' | translate }}
	<a href="#" [routerLink]="'/health-profile/medical-spendings'">{{ 'plan details' | translate }}</a>
	{{ 'for specifics.' | translate }}
</ng-template>
