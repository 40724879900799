import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, map, combineLatest, switchMap, startWith } from 'rxjs';
import { T } from '@transifex/angular';

import { RxjsUtils } from '../../../../utils/rxjs';
import { CoverageTierValues, DeductibleTeaserLocalize } from './deductible-teaser.localize';
import { retrieveDeductiblesFromPlan, defaultDeductibles } from '../../helpers/home-page.helpers';
import { UserCoverageTier } from '../../../../services/api/domain/user-coverage-tier';

import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { TrackingService } from '../../../../services/tracking.service';
import { DeductiblesStoreService } from '../../../../services/stores/deductibles-store/deductibles-store.service';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { UIService } from '../../../../services/ui.service';
import { MeUserStoreService } from '../../../../services/stores/me-user-store/me-user-store.service';

@Component({
	selector: 'app-deductible-teaser',
	templateUrl: './deductible-teaser.component.html',
	styleUrls: ['./deductible-teaser.component.scss'],
})
export class DeductibleTeaserComponent implements OnInit {
	public isMobile$ = this.uiService.isMobile$;
	public isConnected$ = this.deductiblesStoreService.isConnected();
	public isNoNetwork$ = this.userPlanDataStoreService.isNoNetwork();
	public hasRibbonInsurancePartner$ = this.userPlanDataStoreService.hasRibbonInsurancePartner();
	public hasMedicalPlanByKayser$ = this.userPlanDataStoreService.hasMedicalPlanByKayser();
	public isTalonTpa$: Observable<boolean> = this.meUserStoreService.isTalonTpa();

	@ViewChild('deductiblesForm')
	deductiblesForm: TemplateRef<any>;

	@T('Connect your plan')
	connectFormModalTitle: string;

	@T('Connect your insurance account to see how much more you’ll pay before the rest of your coverage kicks in.')
	connectFormModalDescription: string;

	HomePageLocalize = DeductibleTeaserLocalize;

	deductibles$: Observable<{ inNetwork: string; outNetwork: string, noNetwork?: string }>;
	userCoverageTier$: Observable<UserCoverageTier> = this.meUserStoreService.getUserCoverageTier();

	isIndividualData$: Observable<boolean>;
	connectedDeductiblesData$ = this.deductiblesStoreService.get();
	userPlanData$ = this.userPlanDataStoreService.get()

	// Contains individual/family (depending on isIndividualData) deductible details
	// for 'no-network' or 'regular networks' (depending on the network type)
	deductibleDetailsCardData$: Observable<unknown>;

	// Contains individual/family (depending on isIndividualData) out-of-pocket details
	// for 'no-network' or 'regular networks' (depending on the network type)
	outOfPocketDetail$: Observable<unknown>;

	@Input() isDeductibleSyncing: boolean = false;

	get shouldDisplayUnlockButton$() {
		return combineLatest([
			this.isConnected$,
			this.isMobile$,
			this.hasMedicalPlanByKayser$,
			this.hasRibbonInsurancePartner$,
			this.isTalonTpa$
		]).pipe(map(([
			isConnected,
			isMobile,
			hasMedicalPlanByKayser,
			hasRibbonInsurancePartner,
			isTalonTpa
		]) => (
			!isConnected &&
			!isMobile &&
			!hasMedicalPlanByKayser &&
			(hasRibbonInsurancePartner || isTalonTpa)
		)));
	}

	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private deductiblesStoreService: DeductiblesStoreService,
		public healtheeDialogService: HealtheeDialogService,
		private trackingService: TrackingService,
		private meUserStoreService: MeUserStoreService,
		private uiService: UIService
	) {}

	ngOnInit(): void {
		this.deductibles$ = combineLatest([
				this.isNoNetwork$,
				this.userPlanData$.pipe(RxjsUtils.isNotNil())
			])
			.pipe(
				map(([isNoNetwork, planData]) => {
					if (isNoNetwork) return {
						inNetwork: null,
						outNetwork: null,
						noNetwork: planData?.contract?.additionalNetworks[0]?.deductible?.individual?.text
					}

					return retrieveDeductiblesFromPlan(planData);
				}),
				startWith(defaultDeductibles),
			);

		this.isIndividualData$ = combineLatest([
			this.userCoverageTier$,
			this.userPlanDataStoreService.isEmbeddedDeductible(),
		]).pipe(
			map(([userCoverageTiers, isEmbeddedDeductible]: [UserCoverageTier, boolean]) => {
				if (isEmbeddedDeductible) {
					return userCoverageTiers.medicalPlanTier !== CoverageTierValues.FAMILY;
				} else {
					return userCoverageTiers.medicalPlanTier === CoverageTierValues.INDIVIDUAL;
				}
			})
		);

		this.setupDeductibleCardData();
		this.setupOutOfPocketData();
	}

	private setupDeductibleCardData() {
		const deductibleDetailsCardDataForRegularNetworksOrConnectedNoNetwork$ = combineLatest([
			this.isIndividualData$,
			this.connectedDeductiblesData$,
		]).pipe(
			map(([isIndividualData, deductible]) => {
				return isIndividualData
					? deductible?.data?.deductibleDetail?.individual
					: deductible?.data?.deductibleDetail?.family;
			})
		);

		const deductibleDetailsCardDataForDisconnectedNoNetwork$ = combineLatest([
			this.isIndividualData$,
			this.userPlanData$
		]).pipe(
			map(([isIndividualData, planData]) => {
				const deductibleData = planData?.contract?.additionalNetworks[0]?.deductible;
				const individualDeductibleData = deductibleData?.individual?.text
				const familyDeductibleData = deductibleData?.family?.text

				return isIndividualData ? individualDeductibleData : familyDeductibleData;
			})
		);

		this.deductibleDetailsCardData$ = combineLatest([
			this.isConnected$.pipe(map(connected => !connected)),
			this.isNoNetwork$
		]).pipe(
			switchMap(([isDisconnected, isNoNetwork]) => {
				if (isDisconnected && isNoNetwork)
					return deductibleDetailsCardDataForDisconnectedNoNetwork$;
				return deductibleDetailsCardDataForRegularNetworksOrConnectedNoNetwork$;
			})
		);
	}

	private setupOutOfPocketData() {
		const outOfPocketDetailsForRegularNetworksOrConnectedNoNetwork$ = combineLatest([
			this.isIndividualData$,
			this.connectedDeductiblesData$,
		]).pipe(
			map(([isIndividualData, deductible]) => {
				return isIndividualData
					? deductible?.data?.outOfPocketDetail?.individual
					: deductible?.data?.outOfPocketDetail?.family;
			})
		);

		const outOfPocketDetailsForDisconnectedNoNetwork$ = combineLatest([
			this.isIndividualData$,
			this.userPlanData$
		]).pipe(
			map(([isIndividualData, planData]) => {
				const outOfPocketMaxData = planData?.contract?.additionalNetworks[0]?.outOfPocketMax;
				const individualOopData = outOfPocketMaxData?.individual?.text;
				const familyOopData = outOfPocketMaxData?.family?.text;

				return isIndividualData ? individualOopData : familyOopData;
			})
		);

		this.outOfPocketDetail$ = combineLatest([
			this.isConnected$.pipe(map(connected => !connected)),
			this.isNoNetwork$
		]).pipe(
			switchMap(([isDisconnected, isNoNetwork]) => {
				if (isDisconnected && isNoNetwork)
					return outOfPocketDetailsForDisconnectedNoNetwork$;
				return outOfPocketDetailsForRegularNetworksOrConnectedNoNetwork$;
			})
		);
	}

	openConnectionForm() {
		this.healtheeDialogService.open({
			title: this.connectFormModalTitle,
			templateRef: this.deductiblesForm,
		});
		this.trackingService.trackClientEvent('Deductible Connection start', { Source: 'Home Page' });
	}
}
