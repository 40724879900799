import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, take } from 'rxjs';
import { AppointmentFetch, AvailabilityData, SlotData } from '../../../../../../models/appointment.model';
import { UserData } from '../../../../../../models/user-data';
import { UserService } from '../../../../../../services/user.service';
import { Provider } from '../../../helpers/providers.helpers';
import { FlowSteps } from '../provider-appointment.component';
import { ZocdocInfo, ZocdocLocation } from '../../../../../../models/zocdoc-data.model';
import { getAdressFieldsFromGoogleAdressInput } from '../helpers/confirm-appointment-infos';
import { AppointmentService } from '../../../../../../services/appointment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { T } from '@transifex/angular';
import { TrackingService } from 'src/app/services/tracking.service';
import { AppointmentsListStoreService } from 'src/app/services/stores/appointments-list-store/appointments-list-store.service';
import { ScheduleAppointmentEvents } from 'src/app/models/tracking-events.model';
import { CompanyStoreService } from '../../../../../../services/stores/company-store/company-store.service';

@Component({
	selector: 'app-appointment-confirmation',
	templateUrl: './appointment-confirmation.component.html',
	styleUrls: ['./appointment-confirmation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentConfirmationComponent implements OnInit, OnChanges {
	@Input()
	providerData: Provider;

	@Input()
	zocdocData: ZocdocInfo;

	@Input()
	availabilitySelection: AvailabilityData;

	@Input()
	selectedSlot: SlotData;

	@Output()
	goToStep = new EventEmitter<FlowSteps>();

	@Output()
	cancelSubmit = new EventEmitter<void>();

	@T('Ok')
	successAction: string;

	@T('Oh no, something went wrong, and you can’t request to book this appointment. Please try again later.')
	errorMessage: string;

	public userInfoForm: FormGroup;
	public userData: UserData;
	public error = '';
	public gender = [
		{ display: 'Male', id: 'male' },
		{ display: 'Female', id: 'female' },
	];

	public selectedLocation: ZocdocLocation;
	public googlePlaceOptions = { componentRestrictions: { country: ['us','gu','mp'] } };

	public get flowSteps(): typeof FlowSteps {
		return FlowSteps;
	}

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private appointmentsListStoreService: AppointmentsListStoreService,
		private appointmentService: AppointmentService,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
		private trackingService: TrackingService,
		private companyStoreService: CompanyStoreService,
	) {}

	ngOnInit(): void {
		this.getUserData();
	}

	ngOnChanges(): void {
		this.getSelectedLocation();
	}

	private getUserData(): void {
		combineLatest([
			this.userService.userData$,
			this.companyStoreService.isAffiliatedWithTrinet$
		]).pipe(take(1)).subscribe({
			next: ([userData, isAffiliatedWithTrinet]) => {
				this.userData = userData;
				this.buildForm(userData, isAffiliatedWithTrinet);
			},
		});
	}

	private getSelectedLocation(): void {
		this.selectedLocation = this.zocdocData.data.locations.find(
			(location) => location.locationId === this.availabilitySelection?.providerLocationId
		);
	}

	private buildForm(userData: UserData, isAffiliatedWithTrinet: boolean): void {
		const formattedPhoneNumber = this.formatPhoneNumber(userData.phoneNumber);

		this.userInfoForm = this.formBuilder.group({
			firstName: [userData.firstName, [Validators.required]],
			lastName: [userData.lastName, [Validators.required]],
			dob: [userData.birthday, [Validators.required]],
			gender: [userData.gender || this.gender[0].id, [Validators.required]],
			phoneNumber: [formattedPhoneNumber, [Validators.required, Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]],
			email: [!isAffiliatedWithTrinet ? userData.email : null, [Validators.required, Validators.email]],
			userAddress: ['', [Validators.required]],
			userCity: ['', [Validators.required]],
			userState: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
			userZipCode: ['00000', [Validators.required, Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
		});
	}

	private formatPhoneNumber(phone: string): string {
		const match = phone?.match(/^\+?1?(\d{3})(\d{3})(\d{4})$/);
		return match ? `(${match[1]}) ${match[2]}-${match[3]}` : undefined;
	}

	public setAddressFields(selectedAddress): void {
		if (selectedAddress?.address_components) {
			const addressComponents = selectedAddress.address_components;

			this.userInfoForm.patchValue(getAdressFieldsFromGoogleAdressInput(addressComponents));

			this.userInfoForm.get('userAddress').markAsTouched();
			this.userInfoForm.get('userCity').markAsTouched();
			this.userInfoForm.get('userState').markAsTouched();
		}
	}

	public onShowError(field: string) {
		return this.userInfoForm.get(field)?.invalid && this.userInfoForm.get(field)?.touched;
	}

	public onSubmitForm(userFormData: any, isSelectionEdited: boolean, appointment?: AppointmentFetch): void {
		if (this.userInfoForm.invalid) {
			this.userInfoForm.markAllAsTouched();
			this.cdr.detectChanges();
			this.cancelSubmit.emit();
			return;
		}

		const data = {
			...userFormData.selectedSlot,
			...userFormData.availabilitySelection,
			...userFormData.userInfoForm.value,
			phoneNumber: userFormData.userInfoForm.value.phoneNumber.replace(/\D/g, ''),
			npi: this.providerData.npi,
			doctorName: `${this.providerData.firstName} ${this.providerData.lastName}`,
		};

		this.appointmentService.createAppointment(data).subscribe({
			next: (response: any) => {
				this.goToStep.emit(this.flowSteps.submitted);
				if (appointment) this.appointmentsListStoreService.cancelFailedBooking(appointment);
				const metaData = {
					Location: response.locationName,
					'Patient type': response.patientType,
					'Reason for the visit': this.zocdocData.reasons.find(
						(reason) => reason.visitReasonId === userFormData.availabilitySelection.visitReasonId
					).name,
					'Selected time slot': response.date,
					'Edit location / edit date & time': isSelectionEdited,
					'Zocdoc Appointment ID': response.appointmentId,
					"Provider's name": response.doctorName,
					"Provider's speciality": this.providerData.primarySpecialty,
					'user form information': userFormData.userInfoForm.value,
				};
				this.trackingService.trackClientEvent(ScheduleAppointmentEvents.BookingRequested, metaData);
			},
			error: (error: Error) => {
				console.log(error);
				this.showErrorPopUp();
				this.cancelSubmit.emit();
			},
		});
	}

	private showErrorPopUp(): void {
		const snackBarRef = this._snackBar.open(this.errorMessage, this.successAction, {
			duration: 5000,
			panelClass: 'snackbar-failure',
			verticalPosition: 'top',
		});
		snackBarRef.onAction();
	}
}
