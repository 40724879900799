import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { ICON_SIZE } from 'ripple';

import { RxjsUtils } from '../../../utils/rxjs';
import { isNil } from '../../../utils/is/is-nil';
import { isIonic } from '../../../utils/utils';

import CFG from '../../../config/app-config.json';
import { FeatureFlag } from '../../../models/feature-flag.model';
import { PremiumFeature } from '../../../models/premium-feature.model';
import { UserData } from '../../../models/user-data';
import { MY_CARE_TEAM_HOME_PAGE_TEASER, NEW_APP_SWITCH, PREVENTIVE_CARE } from '../../feature-flags/flag-keys';

import { UserService } from '../../../services/user.service';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { FavoriteProvidersStoreService } from '../../../services/stores/favorite-providers-store/favorite-providers-store.service';
import { UnleashService } from '../../../services/unleash.service';
import { HomePageService } from '../services/home-page.service';
import { ServicesResponse } from '../../../services/api/employee-api/helpers/services.response';
import { LanguageService } from '../../../services/language.service';
import { LoggerService } from '../../../../app/services/logger.service';
import { TrackingService } from '../../../services/tracking.service';
import { UIService } from '../../../services/ui.service';
import { PremiumFeatureService } from '../../../services/premium-feature.service';
import { PlanSelectionStoreService } from '../../../services/stores/plan-selection-store/plan-selection-store.service';
import { CompanyStoreService } from '../../../services/stores/company-store/company-store.service';
import { IntercomConversationResponse, IntercomService } from '../../../services/intercom.service';
import { HealtheeDialogService } from '../../../services/healthee-dialog.service';
import { MeUserStoreService } from '../../../services/stores/me-user-store/me-user-store.service';
import { MobileAppService } from '../../../services/mobile-app.service';

import { FfDigitalCard } from '../../../config/feature-flags/ff-digital-card';
import { HealtheeDialogData } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { ClaimReviewErrorComponent } from '../claim-review-error/claim-review-error.component';
import { HomePageLocalize } from './home-page.localize';
import { AuthService } from '../../account/login/auth.service';

const CX_CLAIM_REVIEW_MESSAGE_ID_TAG = CFG.TAGS.cxClaimReviewDeepLinkMessageParameterTag;

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly RIPPLE_ICON_SIZE = ICON_SIZE;
	private cxPredefinedMessages = HomePageLocalize.getCxPredefinedMessages();

	myCareTeamHomePageTeaserEnabled: boolean = this.unleashService.isEnabled(MY_CARE_TEAM_HOME_PAGE_TEASER);
	SERVICES_LENGTH_ON_TEASER = 4;

	FeatureFlag = FeatureFlag;

	userBenefitsMap$: Observable<Map<string, any>> = this.homePageService.userBenefitsMap$;

	showDeductibleTeaser$: Observable<boolean>;

	isHRAPlan$: Observable<boolean>;

	showTelehealthTeaser$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);

	public showPreventiveCareTeaser: boolean = this.unleashService.isEnabled(PREVENTIVE_CARE);

	showNewAppBanner: boolean = this.unleashService.isEnabled(NEW_APP_SWITCH);

	isDigitalCardEnabled$ = this.unleashService.isEnabled$(FfDigitalCard);

	teasersOrder$ = this.homePageService.teasersOrder$;

	selectedBenefits$: Observable<ServicesResponse[]>;

	hasFavoriteProviders$: Observable<boolean> = this.favoriteProvidersStore.get().pipe(
		RxjsUtils.isNotNil(),
		map((providers) => providers.length > 0)
	);

	private userHasGroupNumber$ = this.userPlanDataStoreService.hasGroupNumber();

	isHomePageHealthCardTeaserAvailable$: Observable<boolean>;

	planSelectionPath: string;

	public isMobile$: Observable<boolean> = this.uiService.isMobile$;
	public isIonic: boolean = isIonic();
	public isPlanSelectionEnabled$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();
	isDeductibleSyncing: boolean = false;

	public isPreventiveCareInit$: Observable<boolean> = this.userService.userData$.pipe(
		map((userData: UserData) => userData.isHomePagePreventiveCareInit)
	);

	public hasWellnessTrackerFeature: boolean = false;
	public hasParticipantAccDataFeature: boolean = false;

	private unsubscribe = new Subject<void>();
	private appStateChangeListener: Subscription;

	public showQuantumSupport: boolean = false;

	constructor(
		private userService: UserService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private trackingService: TrackingService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private homePageService: HomePageService,
		private favoriteProvidersStore: FavoriteProvidersStoreService,
		public languageService: LanguageService,
		private meUserStoreService: MeUserStoreService,
		private unleashService: UnleashService,
		public router: Router,
		private route: ActivatedRoute,
		private premiumFeatureService: PremiumFeatureService,
		private uiService: UIService,
		private companyStoreService: CompanyStoreService,
		private intercomService: IntercomService,
		private dialogService: HealtheeDialogService,
		private mobileAppService: MobileAppService,
		private loggerService: LoggerService,
		private authService: AuthService
	) {
		this.showDeductibleTeaser$ = this.userPlanDataStoreService.hasContractByType('contract');
		this.isHRAPlan$ = this.userPlanDataStoreService.isHRAPlan();
	}

	get hasContract$(): Observable<boolean> {
		return this.userPlanDataStoreService.hasContract();
	}

	get firstName$(): Observable<string> {
		return this.userService.userData$.pipe(map((userData) => userData.firstName));
	}

	get showDefaultLayout$(): Observable<boolean> {
		return this.userService.isUserEligible$;
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async (params) => {
			if (params['newApp'] == 'true') {
				try {
					let token = await this.authService.getShortLiveToken()
					window.location.href = `${CFG.newAppRoute}?hcode=${token}`;
				} catch (err) {
					console.error('Error setting new app cookies', err);
				}
			}
		});

		this.planSelectionPath = this.getPlanSelectionPath();
		this.selectedBenefits$ = this.homePageService.getMostPopularCoveredServices(this.SERVICES_LENGTH_ON_TEASER);
		this.isHomePageHealthCardTeaserAvailable$ = combineLatest([
			this.isDigitalCardEnabled$,
			this.meUserStoreService
				.get()
				.pipe(map((me) => (isNil(me) ? false : me.isHomePageHealthCardTeaserAvailable))),
			this.userHasGroupNumber$,
		]).pipe(
			map(([isDigitalCardEnabled, meUserStoreService, userHasGroupNumber]) => {
				return isDigitalCardEnabled && meUserStoreService && userHasGroupNumber;
			})
		);

		this.companyStoreService
			.get()
			.pipe(
				tap((company) => {
					this.hasWellnessTrackerFeature =
						company?.showWellnessTracker === undefined || company?.showWellnessTracker;
					this.hasParticipantAccDataFeature =
						company?.showParticipantAccumulatorData === undefined ||
						company?.showParticipantAccumulatorData;
				})
			)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe();

		this.companyStoreService
			.get()
			.pipe(filter((value) => !!value))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((company) => {
				this.showQuantumSupport = !!(company.name === 'Charter Schools');
			});

		this.triggerIntercomFromLinkOrDeepLink();
	}

	private triggerIntercomFromLinkOrDeepLink() {
		// On web (not native app opened by a deep-link) - links are handled by Angular router's query params
		if (!Capacitor.isNativePlatform()) {
			// copyCxLinkParamsFromUrlToLocalStorageAndReloadPage potentially re-opens this page,
			// (when cleaning params from url) where then ngAfterViewInit() kicks in and "reads" the saved params
			this.copyCxLinkParamsFromUrlToLocalStorageAndReloadPage();
			return;
		}

		// On native apps - deep-links should be opened when the app is brought to the foreground.
		// Componenet and view can be already rendered and loaded, therefore Angular's lifecycle hooks
		// can't be used in this case.
		this.appStateChangeListener = this.mobileAppService.mobileAppOnFront$.subscribe((inForeground) => {
			if (inForeground) {
				// If app is opened by a deep-link, the params would be saved by the
				//  'appUrlOpen' event listener configured in app.component.ts
				this.loggerService.hybridLog('App returned to foreground');
				this.triggerIntercomFromLocalStorageSavedParams();
			}
		});
	}

	ngAfterViewInit() {
		this.triggerIntercomFromLocalStorageSavedParams();
	}

	private copyCxLinkParamsFromUrlToLocalStorageAndReloadPage() {
		this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
			const cxMessageId = params[CX_CLAIM_REVIEW_MESSAGE_ID_TAG];
			this.saveCxLinkMessageIdToLocalStorage(cxMessageId);
			this.clearCxParamsFromUrlAndReloadPage(params);
		});
	}

	private saveCxLinkMessageIdToLocalStorage(cxMessageId: string) {
		if (cxMessageId) {
			localStorage.setItem(CX_CLAIM_REVIEW_MESSAGE_ID_TAG, cxMessageId);
			this.loggerService.hybridLog(
				`[cxLinks] saved param [${CX_CLAIM_REVIEW_MESSAGE_ID_TAG}]="${cxMessageId}" to localstorage`
			);
		}
	}

	private clearCxParamsFromUrlAndReloadPage(params: Params) {
		if (!params[CX_CLAIM_REVIEW_MESSAGE_ID_TAG]) {
			return;
		}

		const updatedParams = { ...params };
		delete updatedParams[CX_CLAIM_REVIEW_MESSAGE_ID_TAG];

		this.router.navigate(['/home'], { queryParams: updatedParams });
	}

	private triggerIntercomFromLocalStorageSavedParams(): void {
		const noteToCxAgent = `Triggered by a <strong>Healthee's campaign</strong><br>`;
		const cxMessageId = localStorage.getItem(CX_CLAIM_REVIEW_MESSAGE_ID_TAG);
		const message = this.cxPredefinedMessages[cxMessageId];

		if (!cxMessageId) return;

		this.loggerService.hybridLog(`[cxLinks] Deleteing [${cxMessageId}] = "${message}"`);
		localStorage.removeItem(CX_CLAIM_REVIEW_MESSAGE_ID_TAG);

		if (!message) {
			this.loggerService.hybridLog(`[cxLinks] message of [${cxMessageId}] is not found.`);
			return;
		}

		this.intercomService.initiateIntercomConversation(message, null, noteToCxAgent).subscribe({
			next: (response) => this.handleIntercomNewConversationSuccess(response),
			error: (error) => this.displayIntercomErrorMessage(error),
		});
	}

	private handleIntercomNewConversationSuccess(response: IntercomConversationResponse) {
		if (!response) return;

		this.loggerService.hybridLog('[cxLinks] Opening intercom conversation id:', response.conversationId);
		this.intercomService.openConversation(response.conversationId);
	}

	private displayIntercomErrorMessage(error: any) {
		if (error) {
			this.loggerService.hybridLog(`[cxLinks] Intercom launch error: ${error}`);
		}

		const options: HealtheeDialogData = {
			hasCloseButton: true,
			disableClose: false,
			title: 'Something went wrong',
			component: ClaimReviewErrorComponent,
			fullHeight: false,
			fullWidth: false,
			maxWidth: '550px',
		};

		this.dialogService.open({ ...options }).subscribe();
	}

	onSeeAllBenefitsClick(): void {
		this.trackingService.trackClientEvent('Home Page See All Benefits Click');
		this.trackingService.trackClientEvent('Benefits Gallery Start', { source: 'Benefits teaser' });
	}

	onRedirectToNewApp(): void {
		this.trackingService.trackClientEvent('Switch to new app click')
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { newApp: 'true' },
			queryParamsHandling: 'merge', // Keep existing query params
		});
	}

	onAllProvidersClick(): void {}

	getPlanSelectionPath() {
		return '/' + CFG.openEnrollmentRoute;
	}

	onDeductibleSyncStatusChanged($event: any) {
		this.isDeductibleSyncing = $event;
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.appStateChangeListener?.unsubscribe();
	}
}
