import { AdditionalBenefit } from '../services/api/domain/additional-benefit';
import { Maybe } from '../utils/types/maybe';

export interface RelatedService {
	iconURL: string;
	title: string;
	type: string;
	_id: string;
}

export interface AdditionalNetwork {
	name: string,
	networkLink: string;
	subjectToDeductible: boolean;
	value: string;
	[key: string]: any;
}

export interface Benefit {
	_id: string;
	inNetwork: string;
	outNetwork: string;
	additionalNetworks: AdditionalNetwork[];
	blueOptionsNetwork: string;
	subjectToDeductibleOut: boolean;
	subjectToDeductibleIn: boolean;
	subjectToDeductableIn?: boolean;
	subjectToDeductibleBlueOptions: boolean;
	iconURL: string;
	title: string;
	desc: string;
	notes: string[];
	benefit?: string;
	covered?: string;
	relatedServices: Array<RelatedService>;
	createdBy2p0?: boolean;
	serviceId?: string;
	networkStructure?: string;
}

export enum Gender {
	Male = 'male',
	Female = 'female',
}

export enum CadenceUnit {
	Visit = 'visit',
	Admission = 'admission',
	Procedure = 'procedure',
	Month = 'month',
	Day = 'day',
	Scan = 'scan',
	Test = 'test',
	Trip = 'trip',
	Transplant = 'transplant',
}

export enum CopayCadence {
	Admission = 'admission',
	FirstNDays = 'first [n] days',
}

export interface Copay {
	amount: number | null;
	upToLimit: number | null;
	unit: Maybe<CadenceUnit>;
	cadence: Maybe<CopayCadence>;
	cadenceCount: number | null;
}

export interface Condition {
	condition: string[]; // medical condition id from insurightsserver
	text: string;
}

export interface AgeRestriction {
	min?: number | null;
	max?: number | null;
}

export interface MemberResponsibility {
	percent?: number | null;
	dollar?: number | null;
	amount?: number | null;
	additionalPercent?: number | null;
	cadence?: Maybe<CadenceUnit>;
}

export interface Preauth {
	timeFrame: number | null;
	timeUnit: 'days' | 'weeks' | 'months' | null;
}

export interface CountLimitation {
	serviceCount: number | null;
	timeUnitCount: number | null;
	timeUnit: 'days' | 'weeks' | 'months' | 'calendar year' | 'plan year' | null;
}

export enum BenefitDocTypes {
	EOC = 'eoc',
	SBC = 'sbc',
}

export interface Contact {
	email: string | null;
	phone: string | null;
}

export interface AdditionalInfo {
	links?: string[] | null;
}

export enum ReimbursementType {
	dollar = '$',
	percent = '%',
}

export interface Reimbursement {
	amount: number | null;
	type: ReimbursementType;
}

export interface Network {
	title: string;
	subjectToDeductible: boolean;
	medicallyNecessary: boolean;
	referralRequired: boolean;
	dependsOnLocation: boolean;
	copay?: Copay;
	preExistConditions?: Condition[];
	ageRestriction?: AgeRestriction;
	memberResponsibility?: MemberResponsibility;
	preAuth?: Preauth;
	countLimitations?: CountLimitation;
	notes: string[];
	contact?: Contact;
	additionalInfo?: AdditionalInfo;
	reimbursement?: Reimbursement;
}

export interface NewBenefit {
	oid: string;
	contractId: string;
	creatorId?: string;
	approverId?: string;
	title: string;
	slug: string;
	service: string;
	notes?: string | null;
	gender?: Maybe<Gender>;
	networks: Network[];
	oldBenefitId?: string;
	docType: BenefitDocTypes;
}
export interface UserBenefits {
	uid: string;
	benefits: NewBenefit[];
	additionalBenefits: AdditionalBenefit[];
}
