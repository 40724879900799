import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { hasTrulyValue } from '../../utils/utils';
import { UserPlanDataStoreService } from '../stores/user-plan-data-store/user-plan-data-store.service';
import { MeUserStoreService } from '../stores/me-user-store/me-user-store.service';
import { transformDeductible } from './deductible.utils';
import { PlanData } from 'src/app/models/plan-data.model';
import { Deductibles } from 'src/app/models/deductibles.model';

@Injectable({ providedIn: 'root' })
export class PlanLevelDeductiblesService {
	public planLeveldeductibles$: Observable<PlanData>;

	constructor(
		private meUserStoreService: MeUserStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {
		this.planLeveldeductibles$ = this.userPlanDataStoreService.get();
	}

	public getPlanLevelDeductibles(): Observable<{ data: any; isMultiNetwork: boolean }> {
		return this.planLeveldeductibles$.pipe(
			map((data) => {
				const isMultiNetwork = data?.contract?.additionalNetworks?.length > 0;

				let deductibleData: Deductibles;
				if (isMultiNetwork && data?.contract?.additionalNetworks?.[0]) {
					deductibleData = data.contract.additionalNetworks[0].deductible;
				} else {
					deductibleData = data?.contract?.deductible;
				}

				return {
					data: deductibleData,
					isMultiNetwork,
				};
			})
		);
	}

	public getDefaultPlanLevelDeductible(networkFieldName: string): Observable<string> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getPlanLevelDeductibles().pipe(
					map(({ data: deductible, isMultiNetwork }) => {
						const planTypeKey = planType === 'individual' ? 'family' : 'individual';

						if (isMultiNetwork) {
							// Handle the simpler structure from additionalNetworks
							return (
								transformDeductible(deductible?.[planType]?.text) ||
								transformDeductible(deductible?.[planTypeKey]?.text)
							);
						} else {
							// Handle the nested structure from contract
							return (
								transformDeductible(deductible?.[planType]?.[networkFieldName]?.text) ||
								transformDeductible(deductible?.[planTypeKey]?.[networkFieldName]?.text)
							);
						}
					})
				)
			)
		);
	}

	public hasDefaultPlanLevelDeductible(networkFieldName: string): Observable<boolean> {
		return this.getDefaultPlanLevelDeductible(networkFieldName).pipe(map((value) => hasTrulyValue(value)));
	}

	public getPlanLevelOutOfPocketMaximums(): Observable<{ data: any; isMultiNetwork: boolean }> {
		return this.planLeveldeductibles$.pipe(
			map((data) => {
				const isMultiNetwork = data?.contract?.additionalNetworks?.length > 0;

				let outOfPocketData;
				if (isMultiNetwork && data?.contract?.additionalNetworks?.[0]) {
					outOfPocketData = data.contract.additionalNetworks[0].outOfPocketMax;
				} else {
					outOfPocketData = data?.contract?.outOfPocketMax;
				}

				return {
					data: outOfPocketData,
					isMultiNetwork,
				};
			})
		);
	}

	public getDefaultPlanLevelOutOfPocketMaximum(networkFieldName: string): Observable<string> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getPlanLevelOutOfPocketMaximums().pipe(
					map(({ data: oopm, isMultiNetwork }) => {
						const planTypeKey = planType === 'individual' ? 'family' : 'individual';

						if (isMultiNetwork) {
							return (
								// There is a model structure mismatch between contract level oopm and plan level oopm. 'text' field is in MN structure, but not in contract level.
								transformDeductible(oopm?.[planType]?.text) ||
								transformDeductible(oopm?.[planTypeKey]?.text)
							);
						} else {
							return (
								transformDeductible(oopm?.[planType]?.[networkFieldName]) ||
								transformDeductible(oopm?.[planTypeKey]?.[networkFieldName])
							);
						}
					})
				)
			)
		);
	}

	public hasDefaultPlanLevelOutOfPocketMaximum(networkFieldName: string): Observable<boolean> {
		return this.getDefaultPlanLevelOutOfPocketMaximum(networkFieldName).pipe(map((value) => hasTrulyValue(value)));
	}

	private defaultCoverageTier(): Observable<string> {
		return this.meUserStoreService.getUserCoverageTier().pipe(map((user) => user.medicalPlanTier));
	}
}
