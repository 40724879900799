// sidebar.component.ts
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, map, Observable, startWith, Subject, switchMap, takeUntil, filter, take, tap, of } from 'rxjs';

import { UIService } from '../../../../services/ui.service';
import { UserService } from '../../../../services/user.service';
import { AppLanguage, LanguageService } from '../../../../services/language.service';
import { TrackingService } from '../../../../services/tracking.service';
import { SidebarService } from '../../../../services/sidebar/sidebar.service';

import { MenuItem } from '../../../../models/menu-item.model';
import { PlanSelectionStoreService } from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { SidebarVisibility } from 'src/app/services/sidebar/helpers/sidebar-visibility';
import { CompanyStoreService } from '../../../../services/stores/company-store/company-store.service';
import { AppManagerService } from '../../../../services/app-manager.service';
import { UnleashService } from '../../../../services/unleash.service';
import { FfNewBrandLogo } from '../../../../config/feature-flags/ff-new-brand-logo';

type SideBarMenuItem = MenuItem & {
	selected?: boolean;
	selectedIcon?: string;
	onClick: () => void;
};

const BenefitsMenuItem = 1;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	BenefitsMenuItem = BenefitsMenuItem;
	isNewBrandLogoEnabled$ = this.unleashService.isEnabled$(FfNewBrandLogo);
	defaultLogo = true;

	private _unsubscribe = new Subject<void>();

	appLogoUrl$ = this.userService._appLogoUrl;
	hasCustomPrimaryLogo$ = this.appLogoUrl$.pipe(
		map((logos) => !!logos?.primary && !logos?.secondary && !!logos?.isCustomPrimaryLogo),
	);

	public openEnrollmentAvailable$: Observable<boolean> = this.userService.user$.pipe(
		switchMap(() => this.companyStoreService.isAffiliatedWithTrinet$),
		switchMap((isAffiliatedWithTrinet) => {
			if (isAffiliatedWithTrinet) {
				return of(false);
			}

			return this.planSelectionStoreService.isPlanSelectionEnabled();
		})
	);

	public setDefaultImage(): void {
		this.defaultLogo = true;
	}

	public menuItems: SideBarMenuItem[] = [
		{
			label: 'Home',
			labelKey: 'menu_item.home',
			icon: '/assets/icons/icon-home.svg',
			selectedIcon: '/assets/icons/icon-home-selected.svg',
			link: '/home',
			onClick: () => {
				this.defaultMenuItemOnClick();
				this.trackNavigationFromMenu('Home Page Start');
			},
		},
		{
			id: BenefitsMenuItem,
			label: 'Benefits',
			labelKey: 'menu_item.my_benefits',
			icon: '/assets/icons/icon-benefits.svg',
			selectedIcon: '/assets/icons/icon-benefits-selected.svg',
			link: '/gallery',
			onClick: () => {
				this.defaultMenuItemOnClick();
				this.trackNavigationFromMenu('Coverage Info Start');
			},
		},
		{
			label: 'Care & Costs',
			labelKey: 'menu_item.care_and_costs',
			icon: '/assets/icons/icon-care-and-costs.svg',
			selectedIcon: '/assets/icons/icon-care-and-costs-selected.svg',
			link: '/care-and-costs',
			onClick: () => {
				this.defaultMenuItemOnClick();
				this.trackNavigationFromMenu('PS - start');
			},
		},
		{
			label: 'My Health Profile',
			labelKey: 'menu_item.my_health_profile',
			icon: '/assets/icons/icon-profile.svg',
			selectedIcon: '/assets/icons/icon-profile-selected.svg',
			user: true,
			link: '/health-profile',
			onClick: () => {
				this.defaultMenuItemOnClick();
				this.trackNavigationFromMenu('Health Profile Start');
			},
		},
		{
			label: 'My Apps',
			hidden: true,
			labelKey: 'menu_item.talon_widget',
			icon: '/assets/icons/icon-my-apps.svg',
			selectedIcon: '/assets/icons/icon-my-apps-selected.svg',
			user: true,
			link: '/my_apps',
			onClick: () => {
				this.defaultMenuItemOnClick();
				this.trackNavigationFromMenu('My Apps Start');
			},
		},
	];

	public showQuantumSupport: boolean = false;

	get isMobile$(): Observable<boolean> {
		return this.uiService.isMobile$;
	}

	get sidenavMode$(): Observable<'over' | 'side'> {
		return this.isMobile$.pipe(map((isMobile) => (isMobile ? 'over' : 'side')));
	}

	get isChatAvailable$(): Observable<boolean> {
		return this.languageService.appLanguage$.pipe(
			// TODO: Should change the string comparisson logic below to explicit flags on the language object
			map((appLanguage: AppLanguage) => appLanguage.locale === 'en' || appLanguage.locale === 'jhs')
		);
	}

	get isDefaultLayout$(): Observable<boolean> {
		return this.userService.isUserEligible$.pipe(startWith(false), takeUntil(this._unsubscribe));
	}

	get shouldHideSidebarByUrl$() {
		return this.uiService.route$.pipe(
			filter((url) => !!url),
			map((url) => this.uiService.shouldRouteHideSidenav(url)),
			takeUntil(this._unsubscribe)
		);
	}

	constructor(
		public languageService: LanguageService,
		public uiService: UIService,
		public sidebarService: SidebarService,
		public appManager: AppManagerService,
		private userService: UserService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private trackingService: TrackingService,
		private companyStoreService: CompanyStoreService,
		private unleashService: UnleashService
	) {}

	ngOnInit(): void {
		this.subscribeToSidebarChanges();

		this.userService.user$.subscribe((user) => {
			if (user?.isTalonTpa && this.unleashService.isEnabled('BO-426-my-apps-page')) {
				const talonWidgetItem = this.menuItems.find((item) => item.label === 'My Apps');
				if (talonWidgetItem) {
					talonWidgetItem.hidden = false;
				}
			}
		});

		this.userService.token$
			.pipe(
				filter((value) => !!value),
				switchMap(() => this.companyStoreService.get().pipe(filter((value) => !!value))),
				takeUntil(this._unsubscribe)
			)
			.subscribe((company) => {
				this.showQuantumSupport = !!(company.name === 'Charter Schools');
			});

		this.appLogoUrl$.subscribe((logos) => {
			if (logos?.primarySm && logos?.secondarySm) this.defaultLogo = false;
		});
	}

	/**
	 * !!! This section is the single source of truth for managing sidebar visibility changes. !!!
	 *
	 * Currently, there are three sources impacting sidebar visibility:
	 * 1. `isDefaultLayout$`: Controls sidebar visibility based on user eligibility; hidden when the user is not eligible.
	 * 2. `shouldHideSidebarByUrl$`: Hides sidebar when a user navigates to specific pages defined in app-config.json (CFG.ui.sidebarHidden).
	 * 3. `overriddenSidebarVisibility$`: Allows a component to temporarily override sidebar visibility (use only
	 *     when you are sure that there are no other more general ways of achieving what you want. And be sure
	 *     to revert to default state promptly).
	 *
	 * If none of these conditions are met, sidebar is visible (and open).
	 * It will be in closed (not hidden) state for mobile users, though.
	 *
	 * Components can also control sidebar shrink/full states by calling `sidebarService.shrink()` and `sidebarService.full()`
	 *
	 * !!! Any modifications to sidebar logic must be centralized here to prevent conflicting states. !!!
	 *
	 */
	private subscribeToSidebarChanges() {
		combineLatest([
			this.isDefaultLayout$,
			this.shouldHideSidebarByUrl$,
			this.isMobile$,
			this.sidebarService.overriddenSidebarVisibility$,
		]).subscribe(([isDefaultLayout, shouldHideSidebarByUrl, isMobile, overriddenSidebarVisibility]) => {
			if (
				(shouldHideSidebarByUrl || !isDefaultLayout || this.uiService.isFullscreenPCT) &&
				overriddenSidebarVisibility !== SidebarVisibility.Visible
			) {
				this.sidebarService.hide();
			} else if (isMobile) {
				this.sidebarService.show();
				this.sidebarService.close();
			} else if (isDefaultLayout) {
				this.sidebarService.show();
				this.sidebarService.open();
			}
		});
	}

	onClickChatWithZoe() {
		this.defaultMenuItemOnClick();
		this.trackNavigationFromMenu('Chat with Zoe Start');
	}

	trackNavigationFromMenu(event: string) {
		this.trackingService.trackClientEvent(event, { source: 'Menu' });
	}

	defaultMenuItemOnClick(): void {
		if (this.uiService.isMobile) {
			this.sidebarService.close();
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

	// We need to update sideBar service with the current state of the sidebar as opening/closing can happen by user
	notifySidebarService(isOpened: boolean) {
		isOpened ? this.sidebarService.open() : this.sidebarService.close();
	}
}
