import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { Intercom as IntercomMobile } from '@capacitor-community/intercom';
import { delay, isIonic } from 'src/app/utils/utils';
import { IntercomService } from 'src/app/services/intercom.service';
import { UserService } from 'src/app/services/user.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { UserData } from 'src/app/models/user-data';
import { PlanData } from 'src/app/models/plan-data.model';
import { environment } from '../../../../environments/environment';
import { take, combineLatest, Observable, map } from 'rxjs';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { TrackingService } from '../../../services/tracking.service';
import { OpenEnrollmentData } from 'src/app/services/plan-selection.service';
import { PlanSelectionStoreService } from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { RxjsUtils } from 'src/app/utils/rxjs';

@Component({
	selector: 'app-intercom-messenger',
	templateUrl: './intercom-messenger.component.html',
	styleUrls: ['./intercom-messenger.component.scss'],
})
export class IntercomMessengerComponent implements OnInit {
	private planData: PlanData;
	private dragging: boolean = false;
	public openEnrollmentData: OpenEnrollmentData;
	public userData: UserData;

	public intercomHigher$: Observable<number> = this.intercomService.intercomHigher$;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _renderer2: Renderer2,
		private intercomService: IntercomService,
		private userService: UserService,
		private conversationService: ConversationService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private trackingService: TrackingService,
		private router: Router,
		private planSelectionStoreService: PlanSelectionStoreService
	) {}

	ngOnInit(): void {
		this.insertScriptIfWeb();

		this.watchUserLogoutAndSyncIntercom();

		const userData$ = this.userService.userData$.pipe(take(1));
		combineLatest([userData$, this.userPlanDataStoreService.get()])
			.pipe(take(1))
			.subscribe({
				next: ([userData, planData]: [UserData, PlanData]) => {
					this.userData = userData;
					this.planData = planData;

					this.userData?.company?.showIntercom ? this.start() : this.showLauncherByMessage();
				},
			});

		const openEnrollmentData$ = this.planSelectionStoreService.get().pipe(
			RxjsUtils.isNotNil(),
			map((data) => data.companyData)
		);

		openEnrollmentData$.pipe(take(1)).subscribe((data) => {
			this.openEnrollmentData = data;
		});
	}

	private watchUserLogoutAndSyncIntercom() {
		this.userService.user$.subscribe({
			next: async (user) => {
				if (!user || !user.token) {
					// Only call Intercom logout on native platforms
					if (Capacitor.isNativePlatform()) {
						await IntercomMobile.logout();
					}
					return;
				}
			},
		});
	}

	private async showCustomLauncher() {
		if (!Capacitor.isNativePlatform()) return;
		const intercomSettings: any = {
			custom_launcher_selector: '.custom-intercom-element',
			hide_default_launcher: true,
			app_id: environment.intercomAppId,
			email: this.userData.email,
			name: `${this.userData.firstName} ${this.userData.lastName}`,
			user_id: this.userData.uid,
			userId: this.userData.uid,
			medicalPlanName: this.planData?.contract?.name,
			dentalPlanName: this.planData?.dental?.name,
			visionPlanName: this.planData?.vision?.name,
			language_override: this.userData.preferredLanguage,
			hide_new_conversation: this.openEnrollmentData?.isTriNet,
		};

		await IntercomMobile.logout();
		await delay(800); // allow some delay between logout and sending the new user data

		// Ensure IntercomMobile is registering the identified user correctly
		IntercomMobile.registerIdentifiedUser({ userId: this.userData.uid });

		// Update user attributes separately
		IntercomMobile.updateUser(intercomSettings);
	}

	public onDragStart(): void {
		this.dragging = true;
	}

	public openMessenger() {
		if (this.dragging) return (this.dragging = false);
		isIonic() ? IntercomMobile.displayMessenger() : window.Intercom('show');
		this.dragging = false;
	}

	private showLauncherByMessage(): void {
		this.conversationService.responseFromZoe$.subscribe((response) => {
			response?.action === 'open_intercom' ? this.start() : this.stop();
		});
	}

	private hideCustomLauncher(): void {
		if (!isIonic()) return;
	}

	private insertScriptIfWeb(): void {
		if (isIonic()) return;

		const APP_ID = 'APP_ID';
		const scriptElement = this._renderer2.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.charset = 'utf-8';

		const intercomSettings: any = {
			app_id: APP_ID,
			company: {
				name: this.userData?.company?.name,
				id: this.userData?.company?.id,
			},
		};

		scriptElement.text = `
				window.intercomSettings = ${JSON.stringify(intercomSettings)};
				(function () {
					var w = window;
					var ic = w.Intercom;
					if (typeof ic === "function") {
						ic('reattach_activator');
						ic('update', w.intercomSettings);
					} else {
						var d = document;
						var i = function () {
							i.c(arguments);
						};
						i.q = [];
						i.c = function (args) { i.q.push(args); };
						w.Intercom = i;
						i('shutdown');
						var l = function () {
							var s = d.createElement('script');
							s.type = 'text/javascript';
							s.async = true;
							s.src = 'https://widget.intercom.io/widget/${APP_ID}';
							var x = d.getElementsByTagName('script')[0];
							x.parentNode.insertBefore(s, x);
						};
						if (document.readyState === 'complete') {
							l();
						} else if (w.attachEvent) {
							w.attachEvent('onload', l);
						} else {
							w.addEventListener('load', l, false);
						}
					}
				})();
		`;

		this._renderer2.appendChild(this._document.body, scriptElement);
	}

	private start(): void {
		if (isIonic()) {
			this.showCustomLauncher();
		} else {
			window['Intercom']('boot', {
				app_id: environment.intercomAppId,
				hide_default_launcher: true,
				email: this.userData.email,
				name: `${this.userData.firstName} ${this.userData.lastName}`,
				user_id: this.userData.uid,
				medicalPlanName: this.planData?.contract?.name,
				dentalPlanName: this.planData?.dental?.name,
				visionPlanName: this.planData?.vision?.name,
				language_override: this.userData.preferredLanguage,
				hide_new_conversation: this.openEnrollmentData?.isTriNet,
			});
			window['Intercom']('onShow', () =>
				this.trackingService.trackClientEvent('Intercom Widget Start', { open_source: this.router.url })
			);
		}

		this.intercomService.onShow();
	}

	private stop(): void {
		if (isIonic()) {
			this.hideCustomLauncher();
		} else {
			window['Intercom']?.('shutdown');
		}

		this.intercomService.onHide();
	}
}
