import { Injectable } from '@angular/core';
import { Intercom } from '@capacitor-community/intercom';
import { Capacitor } from '@capacitor/core';
import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
} from '@capacitor/push-notifications';

const TAG = 'PushNotificationsService';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
	private pushToken: string = '';

	constructor() {}

	// initialize push plugin and add event listers to push notification events
	// this is called from app.component upon app startup
	public async initPushNotifications() {
		console.log(TAG, 'initializing push notifications');

		if (!this.isPushAvailable()) {
			console.log(TAG, 'Push notifications are not available.');
			return;
		}

		let result;

		try {
			result = await PushNotifications.requestPermissions();
		} catch (error) {
			console.error(TAG, 'Error requesting push notification permissions', error);
			return;
		}

		if (!result || result.receive !== 'granted') {
			console.error(TAG, 'Push notification permission denied');
			return;
		}

		try {
			await PushNotifications.register(); // Register with APNS/FCM
		} catch (error) {
			console.error(TAG, 'Error initializing push notifications:', error);
			return;
		}

		this.setupPushEventListeners();
	}

	private isPushAvailable() {
		return Capacitor.isPluginAvailable('PushNotifications');
	}

	private setupPushEventListeners() {
		// Handle Push Registration Success
		PushNotifications.addListener('registration', async (token: Token) => {
			if (token.value === this.pushToken) return; // already registered

			this.pushToken = token.value;
			// DEBUG: console.log(TAG, 'Push registration success, token:', token.value);

			// Send push token to Intercom so that they know how to send push to users
			await Intercom.sendPushTokenToIntercom({ value: token.value });
		});

		// Handle Push Registration Error
		PushNotifications.addListener('registrationError', (error: any) => {
			console.error(TAG, 'Error on push registration:', error);
		});

		// Handle Push Notification Received
		PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
			// Do nothing for now, it is used to open app/intercom
			// DEBUG: console.log(TAG, 'Push received:', notification);
		});

		// Handle Notification Action Performed (when tapped)
		PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
			// Do something meaningful like this.uiService.navigate([route]);
			// For now, it is used to open app/intercom
			// DEBUG: console.log(TAG, 'Push action performed:', notification);
		});
	}
}

