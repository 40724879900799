import { enableProdMode } from '@angular/core';
import '@angular/compiler';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import '@lottiefiles/lottie-player';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Capacitor } from '@capacitor/core';
import { AuthService } from './app/modules/account/login/auth.service';

AuthService.configureClient(environment)

function sentryEnv() {
	let env = 'production';
	const isLocal = window.location.href.indexOf('localhost') > -1;
	const isCapacitorProtocol = window.location.protocol === 'capacitor:';
	const isHttp = window.location.protocol === 'http:';
	const isAndroid = Capacitor.getPlatform() === 'android';

	try {
		if (isLocal && !isCapacitorProtocol && !isAndroid) {
			env = 'local';
		} else if (isHttp && !isAndroid) {
			env = 'local';
		} else {
			const domain = window.location.host;
			if (domain.indexOf('dev.') > -1) {
				env = 'dev';
			} else if (domain.indexOf('qa.') > -1) {
				env = 'qa';
			}
		}
	} catch (e) {
		/* empty */
	}

	return env;
}

Sentry.init({
	dsn: 'https://7a9c49e99b5842959f21bd40bddf8ed0@o4504157069049856.ingest.sentry.io/4504547155836928',
	integrations: [
		new BrowserTracing({
			// tracePropagationTargets: ['localhost', 'https://api.healthee.co'],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],
	environment: sentryEnv(),
	ignoreErrors: ["network error",
		"not connected to the internet",
		"reaching the internet is taking too long",
		"AxiosError: Request aborted",
		"GEOCODER_GEOCODE",
		"Could not load \"util\""
	],

	tracesSampleRate: 0.0,
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
