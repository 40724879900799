import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay } from 'rxjs';

import { DeductiblesData } from './helpers/deductibles-data';
import { Maybe } from '../../../utils/types/maybe';
import { isNil } from '../../../utils/is/is-nil';
import { Store } from '../../../utils/store/store';
import { getPath } from '../../../utils/get-path';
import { isArray } from '../../../utils/is/is-array';

import { PlanData } from '../../../models/plan-data.model';
import { PlanType } from '../../../models/plan-type.model';
import { NetworkStructure } from '../../../models/chat.model';
import { PlanClassType } from '../../../models/domain/plan-class-type';

import { UserApiService } from '../../api/user-api/user-api.service';
import { UIService } from '../../ui.service';

const INVALID_COMPANY_ID_FOR_DEDUCTIBLE_TEASER_AND_TALON = '60181af06df9235a655c24de'; // Kayser

@Injectable({
	providedIn: 'root',
})
export class UserPlanDataStoreService extends Store<void, PlanData> {
	constructor(private userApiService: UserApiService, private uiService: UIService) {
		super();
	}

	protected retrieve(): Observable<Maybe<PlanData>> {
		if (this.uiService.PCTOnlyMode) return of(null);
		return this.userApiService.userPlans();
	}

	medicalDeductibleIndividualData(): Observable<Maybe<DeductiblesData>> {
		return combineLatest([this.isNoNetwork(), this.get()]).pipe(
			map(([isNoNetwork, planData]) => {
				if (isNoNetwork) {
					return {
						noNetwork: planData?.contract?.additionalNetworks[0]?.deductible?.individual
					};
				}
				return getPath<DeductiblesData>(planData, 'contract.deductible.individual');
			}),
			shareReplay(1)
		);
	}

	hasContract(): Observable<Maybe<boolean>> {
		return this.get().pipe(
			map((planData) =>
				!isNil(planData) ? !!planData.contract || !!planData.dental || !!planData.vision : null
			),
			shareReplay(1)
		);
	}

	hasRibbonInsurancePartner(): Observable<Maybe<boolean>> {
		return this.get().pipe(
			map((planData) => !isNil(planData) && !!planData.contract?.metadata?.ribbonInsurancePartner),
			shareReplay(1)
		);
	}

	hasContractByType(contractType: 'contract' | 'dental' | 'vision'): Observable<boolean> {
		return this.get().pipe(
			map((planData) => !isNil(planData) && !isNil(planData[contractType])),
			shareReplay(1)
		);
	}

	isHRAPlan(): Observable<boolean> {
		return this.get().pipe(
			map((planData: PlanData) => planData?.contract?.isHra || false),
			shareReplay(1)
		);
	}

	isNoNetwork(): Observable<boolean> {
		return this.get().pipe(
			map((planData: PlanData) => planData?.contract?.networkStructure === NetworkStructure.NN),
			shareReplay(1)
		);
	}

	isEmbeddedDeductible(): Observable<boolean> {
		return this.get().pipe(
			map((planData: PlanData) => planData?.contract?.hasEmbeddedDeductible || false),
			shareReplay(1)
		);
	}

	getContractsNames(): Observable<string[]> {
		return this.get().pipe(
			map((planData) => [planData.contract?.name, planData.dental?.name, planData.vision?.name]),
			shareReplay(1)
		);
	}

	hasMedicalContractByInsuranceCompanyId(insuranceCompanyId: string): Observable<boolean> {
		return this.get().pipe(
			map(
				(planData) =>
					!isNil(planData) &&
					!isNil(planData.contract) &&
					planData?.contract?.insuranceCompany?._id === insuranceCompanyId
			),
			shareReplay(1)
		);
	}

	planClassType(planType: string): Observable<Maybe<PlanClassType>> {
		return this.get().pipe(
			map((planData) => {
				if (isNil(planData)) {
					return null;
				}
				return planData[planType === 'medical' ? 'contract' : planType]?.planClass;
			})
		);
	}

	benefitsMap(): Observable<Map<string, any>> {
		// TODO: add exact type
		return this.get().pipe(
			map((planData) => {
				const benefitsMap = new Map<string, any>();

				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'contract.contractBenefits'));
				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'dental.contractBenefits'));
				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'vision.contractBenefits'));

				return benefitsMap;
			}),
			shareReplay(1)
		);
	}

	private indexBenefits(benefitMap: Map<string, any>, benefitsList: any[]) {
		if (isNil(benefitsList) || !isArray(benefitsList)) {
			return;
		}
		benefitsList.forEach((benefit: any) => benefitMap.set(benefit.benefit, benefit));
	}

	planTypeByContractId(id: string): Observable<Maybe<PlanType>> {
		return this.get().pipe(
			map((planData) => {
				const planKey = ['contract', 'dental', 'vision'].find(
					(planKey) => planData[planKey].insuranceCompany._id === id
				);
				return planData[planKey]?.planType || null;
			}),
			shareReplay(1)
		);
	}

	planTypeByContractIds(ids: string[]): Observable<{ _id: string; planType: Maybe<PlanType> }[]> {
		return this.get().pipe(
			map((planData) =>
				ids.map((id) => {
					const planKey = ['contract', 'dental', 'vision'].find(
						(planKey) => planData[planKey]?.ribbon === id
					);
					return { _id: id, planType: planData[planKey]?.planType || null };
				})
			),
			shareReplay(1)
		);
	}

	hasGroupNumber(): Observable<boolean> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return false;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (contract?.['groupNumber']) return true;
				}
				return false;
			})
		);
	}

	hasMedicalPlanByKayser(): Observable<boolean> {
		return this.hasMedicalContractByInsuranceCompanyId(INVALID_COMPANY_ID_FOR_DEDUCTIBLE_TEASER_AND_TALON).pipe(
			shareReplay(1)
		);
	}

	getPlansNetworkStructure(): Observable<NetworkStructure> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return null;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (
						contract?.['networkStructure'] !== NetworkStructure.SINGLE &&
						contract?.['networkStructure'] !== undefined
					)
						return contract?.['networkStructure'];
				}
				return NetworkStructure.SINGLE;
			}),
			shareReplay(1)
		);
	}

	getMnNetworkContract(): Observable<NetworkStructure> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return null;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (
						contract?.['networkStructure'] !== NetworkStructure.SINGLE &&
						contract?.['networkStructure'] !== undefined
					)
						return contract;
				}
				return null;
			}),
			shareReplay(1)
		);
	}

	getMnNetworkSortArray(type: string = 'contract'): Observable<string[]> {
		return this.get().pipe(
			map((planData) => {
				if (!planData[type]?.additionalNetworks) return [];
				return planData[type]?.additionalNetworks;
			})
		);
	}
}
