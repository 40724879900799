import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '../../../utils/store/store';
import { UserService } from '../../user.service';

export class TalonMembershipCardData {
	file: string
}

@Injectable({
	providedIn: 'root',
})
export class TalonMembershipCardStoreService extends Store<void, TalonMembershipCardData> {
	constructor(private userService: UserService ) {
		super();
	}

	protected retrieve(): Observable<TalonMembershipCardData> {
		return this.userService.getTalonMembershipCardData();
	}
}
